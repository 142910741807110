import { Box, Menu, MenuItem, Modal, Typography } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import IconCancel from '@material-ui/icons/Cancel';
import IconSave from '@material-ui/icons/Save';
import moment from 'moment';
import React, { MouseEvent, useEffect, useMemo, useState } from 'react';
import { Button, Datagrid, FieldProps, Record, SimpleForm, useMutation, useNotify, useRefresh } from 'react-admin';
import AddressField from 'shared/components/AddressField';
import CurrencyInput from 'shared/components/CurrencyInput';
import { useButtonStyle } from 'shared/components/MenuButtonInputV2';
import { DateInput } from 'shared/components/Pickers';
import { withGridLoader } from 'shared/components/TablePreloader';
import TruncatedField from 'shared/components/TruncatedField';
import { STATUS_MAPPING } from 'shared/constants/appraisalStatus';
import { Appraisal_Statuses } from 'shared/generated/types';
import { useAppraisalStatus, useIsOnlyOneUserActive } from 'shared/hooks/useAppraisalOptions';
import { useIsRestrictedRole } from 'shared/hooks/useResourcePermissions';
import { BackGroundType, getBackgroundColorMapping, getColorMapping } from 'shared/hooks/useRowStyle';
import styles from '../hooks/useAssigneeListStyles';
import AssigneeField from './AssigneeField';
import DateInColumn from './DateInColumn';
import FileNumber from './FileNumber';
import ReportFeeColumn from './ReportFeeColumn';
import StarColumn from './StarColumn';

interface ValuesProps {
  filterValues: any;
  rowStyle?: (record: Record, index: number) => any;
}

export function AppraisalGrid(props: ValuesProps) {
  const { rowStyle, filterValues, ...restProps } = props;
  const activeTab = filterValues.appraisal_status_id;
  const isOnlyOneUserActive = useIsOnlyOneUserActive();
  const canViewReportFee = !useIsRestrictedRole();

  return (
    <>
      <Datagrid optimized {...restProps} rowClick="edit" rowStyle={rowStyle}>
        <StarColumn label="" />
        <FileNumber source="appraisal_file_number" label="File #" />
        {!isOnlyOneUserActive && <AssigneeField label="Assignees" />}
        <AddressField source="location_address" label="Address" />
        <TruncatedField label="Client" source="client_name" />
        {canViewReportFee &&
          (activeTab === 8 ? (
            <ReportFeeColumn label="Quote Fee" source="quote_fee" />
          ) : (
            <ReportFeeColumn label="Report Fee" source="report_fee" />
          ))}
        {activeTab === 8 ? (
          <DateInColumn source="quote_made_date" label="Quote Made" />
        ) : activeTab === 3 ? (
          <DateInColumn source="reviewed_date" label="In Review" />
        ) : activeTab === 4 ? (
          <DateInColumn source="revision_request_date" label="In Revision" />
        ) : activeTab === 6 ? (
          <DateInColumn source="on_hold_date" label="On Hold" />
        ) : activeTab === 7 ? (
          <DateInColumn source="canceled_date" label="Canceled" />
        ) : activeTab === 5 ? (
          <DateInColumn source="completed_date" label="Completed" />
        ) : (
          <DateInColumn source="inspection_date" label="Inspection" />
        )}
        <DateInColumn source="due_date" label="Due" />

        <StatusInput source={'appraisal_status_id'} label={'Status'} />
      </Datagrid>
    </>
  );
}

export default withGridLoader()(AppraisalGrid);

const StatusInput = ({ record, source }: FieldProps) => {
  const refresh = useRefresh();
  const { data: appraisalOptions } = useAppraisalStatus();

  const modalClasses = styles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedStatus, setSelectedStatus] = useState<Appraisal_Statuses>();
  const [selectedDate, setSelectedDate] = useState('');
  const [reportFee, setReportFee] = useState<number>(0);

  const [update] = useMutation();
  const notify = useNotify();

  const open = Boolean(anchorEl);

  const value = record && source ? record['appraisal_status'] : '';

  const statusData = useMemo(() => {
    if (selectedStatus?.id && record) {
      const { label, source } = STATUS_MAPPING[selectedStatus.id];
      const currentDate = record[source];
      if (currentDate) {
        setSelectedDate(currentDate);
      } else {
        setSelectedDate(new Date().toISOString());
      }

      return {
        title: `Change Status to ${selectedStatus.status}?`,
        source,
        label: label.includes('Date') ? label : `${label} Date`,
        showReportFee: record.appraisal_status_id === 8 && selectedStatus.id === 1,
      };
    } else {
      setSelectedDate('');
    }
  }, [record, selectedStatus]);

  useEffect(() => {
    if (statusData?.showReportFee) {
      setReportFee(record?.report_fee || record?.quote_fee || 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusData]);

  const color = getColorMapping(value);
  const backgroundColor = getBackgroundColorMapping(value, BackGroundType.Cell);

  const buttonClasses = useButtonStyle({ color, backgroundColor });

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (anchorEl && !anchorEl.contains(event.target)) {
        handleClose();
      }
    };

    const handleEscapeKey = (event: any) => {
      if (event.key === 'Escape') {
        handleClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [anchorEl]);

  if (!record || !source || !appraisalOptions?.appraisalStatuses.length) {
    return <></>;
  }

  const handleSave = () => {
    console.log({ selectedDate });

    statusData &&
      update(
        {
          type: 'update',
          resource: 'appraisal',
          payload: {
            id: record?.id,
            data: {
              appraisal_status_id: selectedStatus?.id,
              [statusData.source]: selectedDate,
              ...(record?.appraisal_status_id === 8 && selectedStatus?.id === 1 && { report_fee: reportFee }),
            },
          },
        },
        {
          onSuccess: () => {
            setSelectedStatus(undefined);
            notify('appraisal.updated');
            refresh();
          },
        },
      );
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleChange = (choice: any) => {
    setSelectedStatus(choice);
    handleClose();
  };

  return (
    <>
      <Button
        onClick={handleClick}
        size="small"
        className={buttonClasses.root}
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <>
          {value}
          <ArrowDropDownIcon />
        </>
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted={false}
        open={open}
        onClose={handleClose}
        id="basic-menu"
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {appraisalOptions.appraisalStatuses.map((choice: any) => (
          <MenuItem
            key={choice.id}
            onClick={(e) => {
              e.stopPropagation();
              handleChange(choice);
            }}
          >
            {choice.status}
          </MenuItem>
        ))}
      </Menu>
      {statusData && (
        <Modal
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100vw',
          }}
          open={!!(selectedStatus && statusData)}
          onClose={() => setSelectedStatus(undefined)}
          aria-labelledby="appraisal-status-change"
          aria-describedby="appraisal-status-change-modal"
        >
          <SimpleForm toolbar={<span />} onClick={(e) => e.stopPropagation()}>
            <Box className={modalClasses.appraisalListStatusModal}>
              <Box mb={3}>
                <Typography classes={{ root: modalClasses.heading }}>{statusData?.title}</Typography>
              </Box>
              <DateInput
                size="small"
                variant="outlined"
                fullWidth
                label={statusData.label}
                source={statusData?.source ?? ''}
                defaultValue={moment(selectedDate).format('MM/DD/yyyy')}
                onChange={(e: any) => {
                  const value = e.target.value;
                  const dateValue = new Date(value).toISOString();
                  console.log('ll', dateValue);

                  setSelectedDate(dateValue);
                }}
                focused
              />

              {statusData.showReportFee && (
                <CurrencyInput
                  source="report_fee"
                  name="report_fee"
                  defaultValue={reportFee}
                  onChange={(e: any) => setReportFee(e.target.value)}
                  variant="outlined"
                  label="Report Fee"
                />
              )}

              <Box display={'flex'} justifyContent={'space-between'}>
                <Button onClick={() => setSelectedStatus(undefined)} label="Cancel" startIcon={<IconCancel />} />
                <Button onClick={handleSave} label="Save" startIcon={<IconSave />} variant="contained" size="medium" />
              </Box>
            </Box>
          </SimpleForm>
        </Modal>
      )}
    </>
  );
};
