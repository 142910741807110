import { Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import React, { useMemo } from 'react';
import PdfTable from 'shared/components/PdfTable';
import { withPdfWrapper } from 'shared/components/PdfWrapper';
import { useAppraisalClients, useAppraisalOptions } from 'shared/hooks/useAppraisalOptions';
import { displayFormattedDate, formatCurrency, getOptionValue } from 'shared/utils';
// import { Assessment } from 'views/Assessment/types';
import parse from 'html-react-parser';
import { useGetIdentity } from 'react-admin';
import { restrictedRoles } from 'shared/constants/roles';
import { useGetNextTaskSubscription } from 'shared/hooks/useAppraisalTasks';
import { useBulkContactDetailsByIds } from 'shared/hooks/useContactOptions';
import { Appraisal } from '../types';

// Create styles
const styles = StyleSheet.create({
  hr: {
    border: '0.5px solid rgba(0, 0, 0, 0.12)',
    marginTop: 2,
    marginBottom: 2,
  },
  container: {
    marginTop: 10,
    // flex: 1,
    alignItems: 'flex-start',
    flexDirection: 'row',
    '@media max-width: 400': {
      flexDirection: 'column',
    },
  },
  leftColumn: {
    flexDirection: 'column',
    width: '68%',
    marginRight: 10,
    '@media max-width: 400': {
      width: '100%',
      paddingRight: 0,
    },
    '@media orientation: landscape': {
      width: 200,
    },
  },
  rightColumn: {
    flexDirection: 'column',
    width: '30%',
    '@media max-width: 400': {
      width: '100%',
      paddingRight: 0,
    },
    '@media orientation: landscape': {
      width: 200,
    },
  },
  col50: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '45%',
    marginRight: 10,
    '@media max-width: 400': {
      width: '100%',
      paddingRight: 0,
    },
    '@media orientation: landscape': {
      width: 200,
    },
  },
  page: {
    flexDirection: 'column',
    padding: 20,
    fontSize: 11,
  },
  section: {
    marginBottom: 5,
  },
  sectionTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    marginTop: 5,
    marginBottom: 5,
    textDecoration: 'underline',
  },
  property: {
    padding: 2,
    marginBottom: 5,
  },
  pagination: {
    position: 'absolute',
    bottom: 5,
    right: 5,
  },
  footer: {
    position: 'absolute',
    bottom: 5,
    left: 5,
  },
  heading: {
    fontFamily: 'Helvetica-Bold',
  },
});

type AppraisalPdfProps = {
  record: Appraisal;
};
// type AppraisalPdfQueryResponse = {
//   contacts: Contact[];
// };
function AppraisalPdf(props: AppraisalPdfProps) {
  const { record } = props;

  const [appraisalOptions] = useAppraisalOptions();
  const { data: clientsData } = useAppraisalClients();

  const [getTransactionDetails, transactionsResponse] = useBulkContactDetailsByIds();
  const [getOwnerContactDetails, ownerContactsResponse] = useBulkContactDetailsByIds();
  const [getMeetingContactDetails, meetingContactsResponse] = useBulkContactDetailsByIds();
  const [getOrderedByContactDetails, orderedByContactsResponse] = useBulkContactDetailsByIds();

  useMemo(() => {
    getTransactionDetails({ variables: { contact_ids: record.contact_ids } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record.contact_ids]);

  useMemo(() => {
    getOwnerContactDetails({ variables: { contact_ids: record.owner_contact_ids } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record.owner_contact_ids]);

  useMemo(() => {
    getMeetingContactDetails({ variables: { contact_ids: record.inspection_contact_ids } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record.inspection_contact_ids]);

  useMemo(() => {
    getOrderedByContactDetails({ variables: { contact_ids: record.ordered_by_contact_ids } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record.ordered_by_contact_ids]);

  // const transactionsResponse = useQuery<AppraisalPdfQueryResponse>(BULK_CONTACT_DETAIL_QUERY, {
  //   fetchPolicy: 'cache-and-network',
  //   variables: {
  //     contact_ids: record.contact_ids ?? [],
  //   },
  // });

  const { data: nextTaskData } = useGetNextTaskSubscription(record.id);

  const nextTask = useMemo(() => {
    if (!nextTaskData) {
      return '';
    }
    const tasklist = nextTaskData.tasklist[0];

    if (!tasklist || tasklist.tasks_count === 0) {
      return 'None';
    }

    if (tasklist.tasks_count === tasklist.tasks_completed_count) {
      return 'Completed';
    }
    return tasklist.tasks[0].description;
  }, [nextTaskData]);

  const contacts: any = transactionsResponse.data;
  // const { contacts = [] } = transactionsResponse.data ?? {};
  const ownerContacts: any = ownerContactsResponse.data;
  const meetingContacts: any = meetingContactsResponse.data;
  const orderedByContacts: any = orderedByContactsResponse.data;

  const {
    appraisalStatuses = [],
    appraisalPriorities = [],
    commercialPropertyTypes = [],
    commercialPropertySubTypes = [],
    loanTypes = [],
    reportTypes = [],
    appraisalPurposes = [],
    residentialFormTypes = [],
    residentialOwnershipTypes = [],
    residentialStyles = [],
    // clients = [],
  } = appraisalOptions.data ?? {};
  const isResidential = record.property_type_id === 1;
  const isFormReport = record.report_type_id === 1;
  const isFormTypeVisible = isResidential && isFormReport;
  const { identity } = useGetIdentity();
  const { isRestrictedUser } = useMemo(() => {
    return {
      isRestrictedUser: restrictedRoles.includes(identity?.role),
    };
  }, [identity]);

  const formattedNote = record?.notes?.replace(/<\/p>/g, '</p><br />\n');

  return (
    <Page size="A4" style={styles.page}>
      <Text style={styles.pagination} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} fixed />
      <Text style={styles.footer} render={() => `Powered by Appraisal Inbox (https://www.appraisalinbox.com)`} fixed />
      <View wrap={false}>
        <View style={styles.section}>
          <Text>{displayFormattedDate(new Date().toISOString(), 'MM/dd/yyyy')}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading}>
            Appraisal: {record.appraisal_file_number} - {(record.property.location_address || '').replace(/\n|\r/g, '')}
          </Text>
        </View>

        <View>
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Workflow</Text>
            <View style={styles.container}>
              <View style={styles.col50}>
                <Text style={styles.property}>File #: {record.appraisal_file_number}</Text>
                <Text style={styles.property}>
                  Status: {getOptionValue(record.appraisal_status_id, appraisalStatuses, 'status')}
                </Text>
                <Text style={styles.property}>Due Date: {displayFormattedDate(record.due_date)}</Text>
                {record?.reviewed_date && (
                  <Text style={styles.property}>
                    In Review: {displayFormattedDate(record.reviewed_date, 'MM/dd/yyyy')}
                  </Text>
                )}
                {record?.revision_request_date && (
                  <Text style={styles.property}>
                    In Revision: {displayFormattedDate(record.revision_request_date, 'MM/dd/yyyy')}
                  </Text>
                )}
                {record?.on_hold_date && (
                  <Text style={styles.property}>
                    On Hold: {displayFormattedDate(record.on_hold_date, 'MM/dd/yyyy')}
                  </Text>
                )}
                {record?.canceled_date && (
                  <Text style={styles.property}>
                    Canceled: {displayFormattedDate(record.canceled_date, 'MM/dd/yyyy')}
                  </Text>
                )}
                {record?.completed_date && (
                  <Text style={styles.property}>
                    Completed: {displayFormattedDate(record.completed_date, 'MM/dd/yyyy')}
                  </Text>
                )}
              </View>
              <View style={styles.col50}>
                <Text style={styles.property}>
                  Priority: {getOptionValue(record.appraisal_priority_id, appraisalPriorities, 'priority')}
                </Text>
                {/* {record.appraisal_priority_id === 2 && <Text style={styles.property}>Priority: Rush</Text>} */}
                <Text style={styles.property}>Next Task: {nextTask}</Text>
                <Text style={styles.property}>Assignee: {record.assignee_user_account_names?.join(', ')}</Text>
              </View>
            </View>
          </View>
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Inspection</Text>
            <View style={styles.container}>
              <View style={styles.col50}>
                <Text style={styles.property}>Date & Time {displayFormattedDate(record.inspection_date)}</Text>
              </View>
              <View style={styles.col50}>
                <Text style={styles.property}>
                  Meeting With:{' '}
                  {meetingContacts?.contacts?.map((each: any) => each?.full_name + '(' + each?.type + ')')?.join(', ')}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Property</Text>
            <View style={styles.container}>
              <View style={styles.col50}>
                <Text style={styles.property}>Name: {record.property.property_name}</Text>
                <Text style={styles.property}>Address: {record.property.location_address}</Text>
                <Text style={styles.property}>Subdivision: {record.property.subdivision}</Text>
                <Text style={styles.property}>Parcel Number: {record.property.parcel_number}</Text>
                <View style={styles?.container}>
                  <View style={styles?.col50}>
                    <Text style={styles.property}>Zoning: {record.property.zoning}</Text>
                  </View>
                  <View style={styles?.col50}>
                    <Text style={styles.property}>Total Acres: {record.property.total_acres}</Text>
                  </View>
                </View>
              </View>
              <View style={styles.col50}>
                {record.property.property_type_id === 1 && (
                  <>
                    <Text style={styles.property}>
                      Ownership:{' '}
                      {getOptionValue(record.property.residential_ownership_type_id, residentialOwnershipTypes, 'type')}
                    </Text>
                    <Text style={styles.property}>
                      Style: {getOptionValue(record.property.residential_style_id, residentialStyles, 'style')}
                    </Text>
                    <Text style={styles.property}>Bedrooms: {record.property.residential_above_grade_bedrooms}</Text>
                    <Text style={styles.property}>Bathrooms: {record.property.residential_above_grade_bathrooms}</Text>
                    <Text style={styles.property}>Total Area: {record.property.residential_gross_living_area}</Text>
                  </>
                )}
                {record.property.property_type_id === 2 && (
                  <>
                    <Text style={styles.property}>
                      Commercial Property Type:{' '}
                      {getOptionValue(record.property.commercial_property_type_id, commercialPropertyTypes, 'type')}
                    </Text>
                    <Text style={styles.property}>
                      Commercial Sub Type:{' '}
                      {getOptionValue(
                        record.property.commercial_property_subtype_id,
                        commercialPropertySubTypes,
                        'subtype',
                      )}
                    </Text>
                    <Text style={styles.property}>Buildings: {record.property.commercial_buildings}</Text>
                    <View style={styles?.container}>
                      <View style={styles?.col50}>
                        <Text style={styles.property}>Units: {record.property.commercial_units}</Text>
                      </View>
                      <View style={styles?.col50}>
                        <Text style={styles.property}>Total Area: {record.property.commercial_gross_area}</Text>
                      </View>
                    </View>
                  </>
                )}
                <View style={styles?.container}>
                  <View style={styles?.col50}>
                    <Text style={styles.property}>
                      Year Built: {displayFormattedDate(record.property.year_built, 'yyyy')}
                    </Text>
                  </View>
                  <View style={styles.col50}>
                    <Text style={styles.property}>Floors: {record.property.commercial_floors}</Text>
                  </View>
                </View>
              </View>
            </View>
            {ownerContacts?.contacts && ownerContacts?.contacts?.length !== 0 && (
              <Text style={styles.property}>
                Owners:
                {ownerContacts?.contacts?.map((each: any) => each?.full_name + '(' + each?.type + ')')?.join(', ')}
              </Text>
            )}
            <Text style={styles.property}>Legal Description: {record.property.legal_description}</Text>
          </View>

          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Engagement</Text>
            <View style={styles.container}>
              <View style={styles.col50}>
                <Text style={styles.property}>
                  Engaged: {displayFormattedDate(record.engagement_date, 'MM/dd/yyyy')}
                </Text>
                <Text style={styles.property}>
                  Client: {getOptionValue(record.client_id, clientsData?.clients || [], 'name')}
                </Text>
                <Text style={styles.property}>
                  Ordered By:{' '}
                  {orderedByContacts?.contacts
                    ?.map((each: any) => each?.full_name + '(' + each?.type + ')')
                    ?.join(', ')}
                </Text>
                <Text style={styles.property}>Client File #: {record.client_file_number}</Text>
                <Text style={styles.property}>
                  Purpose: {getOptionValue(record.appraisal_purpose_id, appraisalPurposes, 'purpose')}
                </Text>
                <Text style={styles.property}>Loan Type: {getOptionValue(record.loan_type_id, loanTypes, 'type')}</Text>
                {/* {record.borrower_contact_ids?.length > 0 && (
                  <Text style={styles.property}>
                    Borrowers: {getOptionValue(record.borrower_contact_ids[0], clientsData?.clients || [], 'name')}
                  </Text>
                )} */}
                {/* <Text style={styles.property}>
                  Quote Date: {displayFormattedDate(record.quote_accepted_date, 'MM/dd/yyyy')}
                </Text>
                <Text style={styles.property}>
                  Quote Accepted Date: {displayFormattedDate(record.quote_accepted_date, 'MM/dd/yyyy')}
                </Text>
                <Text style={styles.property}>
                  Quote Declined Date: {displayFormattedDate(record.quote_declined_date, 'MM/dd/yyyy')}
                </Text> */}
              </View>
              <View style={styles.col50}>
                <Text style={styles.property}>Client Loan #: {record.client_loan_number}</Text>
                {record.loan_type_id === 4 && <Text style={styles.property}>FHA Case #: {record.fha_case_number}</Text>}
                {record.loan_type_id === 5 && (
                  <Text style={styles.property}>USDA Case # {record.usda_case_number}</Text>
                )}
                {record.loan_type_id === 6 && <Text style={styles.property}>VA Case # {record.va_case_number}</Text>}
                <Text style={styles.property}>
                  Report Type: {getOptionValue(record.report_type_id, reportTypes, 'type')}
                </Text>
                {isFormTypeVisible && (
                  <Text style={styles.property}>
                    Form Types: {getOptionValue(record.residential_form_type_ids, residentialFormTypes, 'type')}
                  </Text>
                )}
                {record.addressed_to_contact_ids?.length > 0 && (
                  <Text style={styles.property}>
                    Addressed to:{' '}
                    {getOptionValue(record.addressed_to_contact_ids[0], clientsData?.clients || [], 'name')}
                  </Text>
                )}
                {!isRestrictedUser && (
                  <>
                    <Text style={styles.property}>Report Fee: {formatCurrency(record.report_fee)}</Text>
                    <Text style={styles.property}>Total Fee: {formatCurrency(record.total_fees)}</Text>
                  </>
                )}
                {/* <Text style={styles.property}>
                  Submitted: {displayFormattedDate(record.submitted_date, 'MM/dd/yyyy')}
                </Text>
                <Text style={styles.property}>
                  Revision: {displayFormattedDate(record.revision_request_date, 'MM/dd/yyyy')}
                </Text> */}
                {/* <Text style={styles.property}>Quote Fee: {formatCurrency(record.quote_fee)}</Text> */}
              </View>
            </View>
          </View>
          {record?.notes && (
            <View style={styles.section}>
              <Text style={styles.sectionTitle}>Notes </Text>
              <Text style={styles.property}>{parse(formattedNote?.toString() || '')}</Text>
            </View>
          )}
          <View>
            <PdfTable
              title="Contacts"
              data={contacts?.contacts || []}
              columns={[
                {
                  name: 'Name',
                  getter: 'full_name',
                },
                {
                  name: 'Type',
                  getter: 'type',
                },
                {
                  name: 'Email',
                  getter: 'email',
                },
                {
                  name: 'Phone',
                  getter: 'phone_number',
                },
              ]}
            />
          </View>

          {/* </View>
            <View style={styles.rightColumn}> */}
          {/* <Text style={styles.sectionTitle}>Appraisal Order</Text>

          <View style={styles.hr}></View>
          <Text style={styles.property}>Report Fee: {formatCurrency(record.report_fee)}</Text>
          <View style={styles.hr}></View> */}
        </View>
        {/* </View> */}
        {/* </View> */}
      </View>
    </Page>
  );
}

export default withPdfWrapper(AppraisalPdf);
