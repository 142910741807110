import { Box, Divider, IconButton, Modal, TextField, Typography } from '@material-ui/core';
import { Add, Cancel, Delete, Done, Edit, Save } from '@material-ui/icons';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Button } from 'react-admin';

import { Autocomplete } from '@material-ui/lab';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import CustomSelectInput from 'shared/components/CustomSelectInput';
import {
  Commercial_Property_Type,
  Residential_Ownership_Types,
  Task,
  Task_Insert_Input,
  Tasklist_Templates,
} from 'shared/generated/types';
import {
  TasklistTemplateResponse,
  useAddTasklist,
  useTaskListByPk,
  useUpdateTasklistByPk,
} from 'shared/hooks/useAppraisalSettingsQueries';
import { simpleFormContainer } from 'shared/hooks/useEditFormStyle';

interface TemplateFormProps {
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  tasklistTemplatesData?: TasklistTemplateResponse;
  refetchTasklistTemplateData: any;
  selectedTasklistTemplate?: Tasklist_Templates;
  setSelectedTasklistTemplate: Dispatch<SetStateAction<Tasklist_Templates | undefined>>;
}

const TemplateForm = ({
  isOpen,
  setOpen,
  tasklistTemplatesData,
  refetchTasklistTemplateData,
  selectedTasklistTemplate,
  setSelectedTasklistTemplate,
}: TemplateFormProps) => {
  const [isDirty, setDirty] = useState<{ [key: string]: boolean }>({});

  const { data: tasklistDetail } = useTaskListByPk(selectedTasklistTemplate?.id);

  const isEdit = !!tasklistDetail;
  const formClasses = simpleFormContainer();

  const [name, setName] = useState('');
  const [tasks, setTasks] = useState<Task[]>([]);
  const [editTask, setEditTask] = useState<Task>();

  const [property_type_id, set_property_type_id] = useState(1);
  const [residential_ownership_type, set_residential_ownership_type] = useState<Residential_Ownership_Types | null>(
    null,
  );
  const [commercial_property_type, set_commercial_property_type] = useState<Commercial_Property_Type | null>(null);

  const clearAllState = () => {
    setName('');
    setTasks([]);
    set_property_type_id(1);
    set_residential_ownership_type(null);
    set_commercial_property_type(null);
    setDirty({});
  };

  const [addTaskList, { data: addTasklistData }] = useAddTasklist();
  const [updateTaskList, { data: updateTasklistData }] = useUpdateTasklistByPk();

  const handleConfirmClick = () => {
    const variables = {
      name,
      tasks: { data: tasks },
      template_commercial_property_type_id: commercial_property_type?.id,
      template_property_type_id: property_type_id,
      template_residential_form_type_ids: null,
      template_residential_ownership_type_id: residential_ownership_type?.id,
    };
    if (!!tasklistDetail?.tasklist_by_pk) {
      const updates: Task_Insert_Input[] = [];
      tasks.map((task) => {
        updates.push({
          description: task.description,
          order: task.order,
          tasklist_id: task.tasklist_id,
          appraisal_id: task.appraisal_id,
          assignee_user_account_id: task.assignee_user_account_id,
          color: task.color,
          completed_date: task.completed_date,
          due_date: task.due_date,
        });
        return null;
      });
      updateTaskList({
        variables: { ...tasklistDetail.tasklist_by_pk, ...variables, tasks: updates },
      });
    } else {
      addTaskList({
        variables,
      });
    }
  };

  useEffect(() => {
    if (addTasklistData || updateTasklistData) {
      refetchTasklistTemplateData();
      setSelectedTasklistTemplate(undefined);
      clearAllState();
      setOpen(false);
    }
  }, [addTasklistData, refetchTasklistTemplateData, setOpen, setSelectedTasklistTemplate, updateTasklistData]);

  useEffect(() => {
    if (tasklistDetail && tasklistTemplatesData && selectedTasklistTemplate) {
      const {
        name,
        template_property_type_id,
        template_residential_ownership_type_id,
        tasks,
        template_commercial_property_type_id,
      } = tasklistDetail.tasklist_by_pk;

      const residential_ownership_type = tasklistTemplatesData?.residentialOwnershipTypes.find(
        (type) => type.id === template_residential_ownership_type_id,
      );
      const commercial_property_type = tasklistTemplatesData?.commercialPropertyTypes.find(
        (type) => type.id === template_commercial_property_type_id,
      );

      setName(name);
      setTasks(tasks);
      set_property_type_id(template_property_type_id as number);
      set_residential_ownership_type(residential_ownership_type || null);
      set_commercial_property_type(commercial_property_type || null);
    }
  }, [tasklistDetail, tasklistTemplatesData, selectedTasklistTemplate]);

  const { template_default, id } = tasklistDetail?.tasklist_by_pk || {};

  const isEditing = useMemo(() => {
    return tasks.some((task) => task.id === 'edit') || editTask?.id;
  }, [editTask, tasks]);

  const isFormDirty = useMemo(() => {
    return Object.values(isDirty).some((item) => item);
  }, [isDirty]);

  if (!tasklistTemplatesData) {
    return null;
  }

  const restrictedEdit = isEdit && !!template_default;

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const reorderedTask = reorder(tasks, result.source.index, result.destination.index);

    if (reorderedTask.length > 0) {
      setDirty((prev) => ({ ...prev, tasks: true }));
      const sanitizedTasksOrders = reorderedTask.map((task, key) => ({ ...task, order: key + 1 }));
      setTasks(sanitizedTasksOrders);
    }
  };

  const reorder = (list: Task[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const removedItem = result[startIndex];
    result.splice(startIndex, 1);
    result.splice(endIndex, 0, removedItem);

    return result;
  };

  // const grid = 2;

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    // padding: grid * 2,
    // margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? '#f5f5f5' : '#ffffff',

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? '#f5f5f5' : '#ffffff',
    // padding: grid,
    // width: 250,
  });

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        clearAllState();
        setSelectedTasklistTemplate(undefined);
        setOpen(false);
      }}
    >
      <Box className={formClasses.addTasklistModal} px={3}>
        <Typography classes={{ root: formClasses.modalHeading }}>
          {isEdit ? (template_default ? 'Edit Default' : 'Edit') : 'Add'} Tasklist Template
        </Typography>
        <Divider />

        <Box py={1} px={3} style={{ overflow: 'auto', maxHeight: '70vh' }}>
          {!restrictedEdit && (
            <Box>
              <Typography className={formClasses.headingSecondary}>TASKLIST</Typography>

              <Divider />

              <Box my={3}>
                <TextField
                  label={'Name'}
                  variant={'outlined'}
                  fullWidth
                  size={'small'}
                  value={name}
                  onChange={(e) => {
                    setDirty((prev) => ({ ...prev, name: true }));
                    setName(e.target.value);
                  }}
                />
              </Box>

              <Typography className={formClasses.headingSecondary}>PROPERTY TYPE</Typography>

              <Divider />

              <CustomSelectInput
                label=""
                fullWidth
                customCSS={{
                  chips: {
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                  },
                }}
                variant="outlined"
                choices={tasklistTemplatesData?.propertyTypes ?? []}
                source={''}
                onChange={(val: number) => {
                  setDirty((prev) => ({ ...prev, property_type_id: true }));
                  set_property_type_id(val);
                }}
                optionText="type"
                value={property_type_id}
              />

              {property_type_id === 1 && (
                <>
                  <Box mb={2}>
                    <Autocomplete
                      getOptionLabel={(option) => option.type ?? ''}
                      value={residential_ownership_type}
                      options={tasklistTemplatesData?.residentialOwnershipTypes ?? []}
                      onChange={(e, value) => {
                        setDirty((prev) => ({ ...prev, residential_ownership_type: true }));

                        set_residential_ownership_type(value);
                      }}
                      renderInput={(params) => <TextField {...params} label={'Ownership'} variant={'outlined'} />}
                      size="small"
                    />
                  </Box>
                  {/* <Box mb={2}>
                    <Autocomplete
                      getOptionLabel={(option) => option.style ?? ''}
                      value={residential_style}
                      options={tasklistTemplatesData?.residentialStyles ?? []}
                      onChange={(e, value) => {
                        set_residential_style(value);
                      }}
                      renderInput={(params) => <TextField {...params} label={'Style'} variant={'outlined'} />}
                      size="small"
                    />
                  </Box> */}
                </>
              )}
              {property_type_id === 2 && (
                <>
                  <Box mb={2}>
                    <Autocomplete
                      getOptionLabel={(option) => option.type ?? ''}
                      value={commercial_property_type}
                      options={tasklistTemplatesData?.commercialPropertyTypes ?? []}
                      onChange={(e, value) => {
                        setDirty((prev) => ({ ...prev, commercial_property_type: true }));

                        set_commercial_property_type(value);
                      }}
                      renderInput={(params) => <TextField {...params} label={'Property Type'} variant={'outlined'} />}
                      size="small"
                    />
                  </Box>
                  {/* <Box mb={2}>
                    <Autocomplete
                      getOptionLabel={(option) => option.subtype ?? ''}
                      value={commercial_property_subtype}
                      options={tasklistTemplatesData?.commercialPropertySubTypes ?? []}
                      onChange={(e, value) => {
                        set_commercial_property_subtype(value);
                      }}
                      renderInput={(params) => <TextField {...params} label={'Property Sub Type'} variant={'outlined'} />}
                      size="small"
                    />
                  </Box> */}
                </>
              )}

              <Divider className={formClasses.divider} />
            </Box>
          )}

          <Box my={2}>
            <Box p={1}>
              <Typography className={formClasses.headingSecondary}>TASKS</Typography>
            </Box>
            <Divider />
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {tasks?.map((task, key) => (
                      <Draggable key={task.order} draggableId={`${task.order}`} index={key}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          >
                            {task.id === 'edit' || (editTask && task.id === editTask.id) ? (
                              <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} p={1}>
                                <Box flex={1}>
                                  <TextField
                                    autoFocus
                                    multiline
                                    onFocus={(e) => {
                                      const temp_value = e.target.value;
                                      e.target.value = '';
                                      e.target.value = temp_value;
                                    }}
                                    name="description"
                                    variant="outlined"
                                    value={task.description}
                                    size={'small'}
                                    fullWidth
                                    onChange={(e) => {
                                      setTasks((prev) => {
                                        const newArr = [...prev];
                                        newArr[key].description = e.target.value;
                                        newArr[key].tasklist_id = id;
                                        return newArr;
                                      });
                                    }}
                                  />
                                </Box>
                                <IconButton
                                  onClick={() => {
                                    setDirty((prev) => ({ ...prev, description: true }));

                                    setTasks((prev) => {
                                      const newArr = [...prev];
                                      if (task.id === 'edit') {
                                        delete newArr[key].id;
                                      } else {
                                        setEditTask(undefined);
                                      }

                                      return newArr.filter((item) => !!item.description);
                                    });
                                  }}
                                >
                                  <Done />
                                </IconButton>
                                <IconButton
                                  onClick={() => {
                                    setTasks((prev) => {
                                      const newArr = [...prev];
                                      if (task.id === 'edit') {
                                        newArr.pop();
                                      } else if (editTask) {
                                        const modifiedIndex = newArr.findIndex((item) => item.id === editTask.id);
                                        newArr[modifiedIndex] = editTask;
                                      }
                                      return newArr;
                                    });
                                    setEditTask(undefined);
                                  }}
                                >
                                  <Cancel />
                                </IconButton>
                              </Box>
                            ) : (
                              <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} p={1}>
                                <Box flex={1}>
                                  <Typography>{task.description}</Typography>
                                </Box>
                                <Box>
                                  <IconButton
                                    onClick={() => {
                                      setEditTask((prev) => ({ ...prev, ...task }));
                                    }}
                                  >
                                    <Edit />
                                  </IconButton>

                                  <IconButton
                                    onClick={() => {
                                      setTasks((prev) => {
                                        return prev.filter((item) => item.order !== task.order);
                                      });
                                      setDirty((prev) => ({ ...prev, delete: true }));
                                    }}
                                  >
                                    <Delete />
                                  </IconButton>
                                </Box>
                              </Box>
                            )}
                            <Divider />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}

                    {/* <Divider /> */}

                    <Box textAlign={'end'} p={2}>
                      <Button
                        label="Add Task"
                        startIcon={<Add />}
                        onClick={() => {
                          setTasks((prev: any) => {
                            const newArr = [...prev];
                            newArr.push({ order: prev.length + 1, id: 'edit' });
                            return newArr;
                          });
                        }}
                      />
                    </Box>
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
        </Box>
        <Divider />

        <Box className={formClasses.modalButtons}>
          <Button
            label="ra.action.cancel"
            onClick={() => {
              clearAllState();
              setSelectedTasklistTemplate(undefined);
              setOpen(false);
            }}
          >
            <Cancel />
          </Button>

          <Button
            onClick={handleConfirmClick}
            label="SAVE"
            variant="contained"
            size="medium"
            startIcon={<Save />}
            disabled={isEditing || !isFormDirty}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default TemplateForm;
