import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { Commercial_Property_Types } from 'shared/generated/types';

const SEARCH_COMMERCIAL_PROPERTY_TYPES = gql`
  query SearchCommercialPropertyTypes($search: String) {
    search_commercial_property_types(
      args: { search: $search }
      order_by: { commercial_property_type_order: asc, commercial_property_subtype_order: asc }
    ) {
      commercial_property_type_id
      commercial_property_type
      commercial_property_subtype
      commercial_property_subtype_id
      commercial_property_type_order
      commercial_property_subtype_order
    }
  }
`;

interface SearchResponse {
  search_commercial_property_types: Array<Commercial_Property_Types>;
}
interface SearchRequest {
  search: string;
}
export const useSearchCommercialPropertyTypes = () => {
  return useLazyQuery<SearchResponse, SearchRequest>(SEARCH_COMMERCIAL_PROPERTY_TYPES);
};

interface CommercialPropertyTypes {
  commercial_property_types: Array<Commercial_Property_Types>;
}

const GET_COMMERCIAL_PROPERTY_TYPES = gql`
  query GetCommercialPropertyTypes {
    commercial_property_types(
      order_by: { commercial_property_type_order: asc, commercial_property_subtype_order: asc }
    ) {
      commercial_property_type_id
      commercial_property_type
      commercial_property_subtype
      commercial_property_subtype_id
      commercial_property_type_order
      commercial_property_subtype_order
    }
  }
`;

export const useCommercialPropertyTypes = () => {
  return useQuery<CommercialPropertyTypes>(GET_COMMERCIAL_PROPERTY_TYPES);
};
