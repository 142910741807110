import omit from 'lodash/omit';
import React from 'react';
import {
  Edit,
  EditProps,
  Record,
  ResourceContextProvider,
  TitleProps,
  useGetIdentity,
  useMutation,
  useNotify,
} from 'react-admin';
import { useDispatch } from 'react-redux';
import { standardMutationRoles } from 'shared/constants/roles';
import { Appraisal } from 'views/Appraisal/types';
import { FetchMenuCount } from './reducer';
// import PdfTab from './components/PdfTab';
import { gql, useMutation as useApolloMutation } from '@apollo/client';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import EditForm from './EditForm';
// import moment from 'moment';

const EditTitle = ({ record }: TitleProps) => (
  <span>Appraisal: {record ? getAppraisalTitle(record as Appraisal) : ''}</span>
);

const EditAppraisal = (props: EditProps) => {
  const dispatch = useDispatch();
  const { identity } = useGetIdentity();
  const notify = useNotify();
  const onSuccess = () => {
    dispatch(FetchMenuCount());
    notify('appraisal.updated');
  };
  const INSERT_INVOICE = gql`
    mutation ($invoice_number: String, $invoice_date: timestamptz, $due_date: timestamptz, $client_id: uuid!) {
      insert_invoice_one(
        object: {
          invoice_number: $invoice_number
          invoice_date: $invoice_date
          due_date: $due_date
          client_id: $client_id
        }
      ) {
        id
      }
    }
  `;

  const [update] = useMutation({ type: 'update', resource: 'property', payload: {} });
  const [insertInvoice] = useApolloMutation(INSERT_INVOICE);
  const [updateInvoice] = useMutation({ type: 'update', resource: 'invoice', payload: {} });

  if (!identity) {
    return (
      <Grid container direction="column" alignItems="center" style={{ marginTop: '20px' }}>
        <CircularProgress />
      </Grid>
    );
  }
  return (
    <ResourceContextProvider value="appraisal">
      <Edit
        {...props}
        onSuccess={onSuccess}
        transform={transform}
        title={<EditTitle />}
        resource="appraisal"
        mutationMode="optimistic"
      >
        <EditForm></EditForm>
      </Edit>
    </ResourceContextProvider>
  );

  async function transform(data: Record) {
    const appraisal: Appraisal = {
      ...(data as Appraisal),
    };

    let response: any;
    if (data?.invoice_id) {
      await updateInvoice({
        payload: {
          id: appraisal?.invoice_id,
          data: {
            ...appraisal.invoice,
          },
        },
      });
    } else {
      if (appraisal?.invoice) {
        response = await insertInvoice({
          variables: {
            ...(appraisal?.invoice?.invoice_number && { invoice_number: appraisal?.invoice?.invoice_number }),
            // invoice_date: appraisal?.invoice?.invoice_date || moment().format(),
            // due_date: appraisal?.invoice?.due_date || null,
            ...(appraisal?.invoice?.invoice_date && { invoice_date: appraisal?.invoice?.invoice_date }),
            ...(appraisal?.invoice?.due_date && { due_date: appraisal?.invoice?.due_date }),
            client_id: appraisal?.client_id,
          },
        });
      }
    }
    if (standardMutationRoles.includes(identity?.role ?? '')) {
      await update({
        payload: {
          id: appraisal.property.id,
          data: {
            ...omit(appraisal.property, 'id', 'organization_id', 'user_account_id', 'updated_by_user_account_id'),
            property_type_id: appraisal.property_type_id,
          },
        },
      });
    }
    return omit(
      { ...appraisal, ...(!data?.invoice_id && { invoice_id: response?.data?.insert_invoice_one?.id }) },
      'property',
      'assignee_user_account_names',
    ) as Record;
  }
};

function getAppraisalTitle(record: Appraisal) {
  return [record.appraisal_file_number, record.property?.property_name, record.property.location_address]
    .filter(Boolean)
    .join(' - ');
}
export default EditAppraisal;
