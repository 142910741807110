import DateFnsUtils from '@date-io/date-fns';
import { Box, Checkbox, Grid, Typography } from '@material-ui/core';
import { AccessTime } from '@material-ui/icons';
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { Button, FieldTitle, useInput } from 'react-admin';
import { useFormState } from 'react-final-form';

import AutocompleteArrayInput from 'shared/components/AutocompleteArrayInput';
import AutocompleteInput from 'shared/components/AutocompleteInput';
import CurrencyInput from 'shared/components/CurrencyInput';
import { isValidDate, pushOrRemove } from '../helpers';
import { useGetOptions } from '../queries';
import { useStyles } from '../styles';

const INITIAL_FORM_TYPES_IDS = [10, 12, 13, 14, 17, 26];
const INITIAL_APPRAISAL_PURPOSE_IDS = [26, 30, 5, 12, 9, 19];
const INITIAL_REPORT_TYPE_IDS = [1, 2];

export interface OrderData {
  appraisal_status_id: number;
  appraisal_priority_id: number;
  dateVal: MaterialUiPickersDate | null;
  showReportFee: boolean;
}

interface OrderProps {
  orderData: OrderData;
  setOrderData: Dispatch<SetStateAction<OrderData>>;
}

export const OrderDetails = ({ orderData, setOrderData }: OrderProps) => {
  const classes = useStyles();
  const { data: options, loading: optionsLoading } = useGetOptions();
  const [showFormTypeSelector, setShowFormTypeSelector] = useState(false);
  const [showPurposeSelector, setShowPurposeSelector] = useState(false);
  const [showReportTypeSelector, setShowReportTypeSelector] = useState(false);

  const [timeVal, setTimeVal] = useState<any>(null);

  const { showReportFee, appraisal_priority_id, dateVal } = orderData;

  const { values: formData } = useFormState();

  const { input: residentialFormTypeInput } = useInput({ source: 'residential_form_type_ids' });
  const { input: appraisalPurposeInput } = useInput({ source: 'appraisal_purpose_id' });
  const { input: reportTypeInput } = useInput({ source: 'report_type_id' });

  return (
    <>
      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
        <Typography variant="subtitle1">Request for Quote?</Typography>
        <Checkbox
          onChange={(e) => {
            setOrderData((prev) => ({ ...prev, showReportFee: !prev.showReportFee }));
          }}
          style={{ color: '#2196F3' }}
          checked={!showReportFee}
        />
      </Box>
      <Box my={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
        <Typography variant="subtitle1">Rush?</Typography>
        <Checkbox
          onChange={(e) => {
            setOrderData((prev) => ({ ...prev, appraisal_priority_id: e.target.checked ? 2 : 1 }));
          }}
          style={{ color: '#2196F3' }}
          checked={appraisal_priority_id === 2}
        />
      </Box>

      {showPurposeSelector ? (
        <AutocompleteInput
          label="Appraisal Purpose"
          fullWidth
          source="appraisal_purpose_id"
          optionText="purpose"
          variant="outlined"
          choices={options?.appraisalPurposes ?? []}
          disabled={optionsLoading}
        />
      ) : (
        <>
          <Typography variant="subtitle1">Appraisal Purpose</Typography>
          <Box mb={2.5} mt={0.5}>
            {options?.appraisalPurposes.map((item) => {
              if (item.id && item.purpose && INITIAL_APPRAISAL_PURPOSE_IDS.includes(item.id)) {
                return (
                  <Button
                    key={item.id}
                    variant={formData.appraisal_purpose_id === item.id ? 'contained' : 'outlined'}
                    className={classes.chipButton}
                    label={item.purpose}
                    onClick={() => {
                      appraisalPurposeInput.onChange(formData.appraisal_purpose_id === item.id ? null : item.id);
                    }}
                  />
                );
              }
            })}

            <Button
              variant={'outlined'}
              className={classes.chipButton}
              label="More"
              onClick={() => setShowPurposeSelector(true)}
            />
          </Box>
        </>
      )}

      {showReportTypeSelector ? (
        <AutocompleteInput
          label="Report Type"
          fullWidth
          variant="outlined"
          source="report_type_id"
          optionText="type"
          choices={options?.reportTypes ?? []}
        />
      ) : (
        <>
          <Typography variant="subtitle1">Report Type</Typography>
          <Box mb={2.5} mt={0.5}>
            {options?.reportTypes.map((item) => {
              if (item.id && item.type && INITIAL_REPORT_TYPE_IDS.includes(item.id)) {
                return (
                  <Button
                    key={item.id}
                    variant={formData.report_type_id === item.id ? 'contained' : 'outlined'}
                    className={classes.chipButton}
                    label={item.type}
                    onClick={() => {
                      reportTypeInput.onChange(formData.report_type_id === item.id ? null : item.id);
                    }}
                  />
                );
              }
            })}
            <Button
              variant={'outlined'}
              className={classes.chipButton}
              label="More"
              onClick={() => setShowReportTypeSelector(true)}
            />
          </Box>
        </>
      )}

      {formData.report_type_id === 1 && (
        <Grid>
          {showFormTypeSelector ? (
            <AutocompleteArrayInput
              label="Form Types"
              fullWidth
              variant="outlined"
              source="residential_form_type_ids"
              optionText="type"
              choices={options?.residentialFormTypes ?? []}
            />
          ) : (
            <>
              <Typography variant="subtitle1">Form Type</Typography>

              <Box mb={2.5} mt={0.5}>
                {options?.residentialFormTypes.map((item) => {
                  if (item.id && item.type && INITIAL_FORM_TYPES_IDS.includes(item.id)) {
                    return (
                      <Button
                        key={item.id}
                        variant={formData.residential_form_type_ids?.includes(item.id) ? 'contained' : 'outlined'}
                        className={classes.chipButton}
                        label={item.type}
                        onClick={() => {
                          if (item.id) {
                            const newData = pushOrRemove(formData.residential_form_type_ids || [], item.id);
                            residentialFormTypeInput.onChange(newData);
                          }
                        }}
                      />
                    );
                  }
                })}
                <Button
                  variant={'outlined'}
                  className={classes.chipButton}
                  label="More"
                  onClick={() => setShowFormTypeSelector(true)}
                />
              </Box>
            </>
          )}
        </Grid>
      )}

      {showReportFee && <CurrencyInput fullWidth variant="outlined" label="Report Fee" source="report_fee" />}

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container spacing={1}>
          <Grid item sm={12} md={7} lg={7}>
            <KeyboardDatePicker
              label={<FieldTitle label="Due Date" source="due_date" />}
              autoOk
              variant="dialog"
              inputVariant="outlined"
              value={dateVal}
              format="MM/dd/yyyy"
              onChange={function (date: MaterialUiPickersDate): void {
                setOrderData((prev) => ({ ...prev, dateVal: date }));
                //for setting 5:00 PM as default time
                if (date && !timeVal) {
                  date.setHours(17); // Set custom hours
                  date.setMinutes(0); // Set custom minutes
                  date.setSeconds(0);
                  setTimeVal(date.toLocaleString('en-US'));
                }
              }}
              margin="dense"
              clearable
              fullWidth
            />
          </Grid>
          <Grid item sm={12} md={5} lg={5}>
            <KeyboardTimePicker
              label={<FieldTitle label="Time" source="d_time" />}
              variant="dialog"
              inputVariant="outlined"
              autoOk
              value={timeVal}
              onChange={(date: any) => {
                setTimeVal(date.toLocaleString('en-US'));
                if (dateVal && date !== 'Invalid Date') {
                  const tVal = new Date(date);
                  const dVal = new Date(dateVal);
                  dVal.setHours(tVal.getHours());
                  dVal.setMinutes(tVal.getMinutes());
                  dVal.setSeconds(tVal.getSeconds());
                  isValidDate(dVal) && setOrderData((prev) => ({ ...prev, dateVal: dVal }));
                }
              }}
              margin="dense"
              clearable
              keyboardIcon={<AccessTime />}
              fullWidth
              disabled={!timeVal}
            />
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </>
  );
};
