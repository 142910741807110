import { Record } from 'react-admin';
import { AppraisalStatus } from 'shared/constants/appraisalStatus';
import {
  Appraisal_Activities,
  Appraisal as GeneratedAppraisal,
  Appraisals as GeneratedAppraisals,
  Message_Thread,
  Messages as MessagesType,
} from 'shared/generated/types';

type WithAppraisalStatus = {
  appraisal_status?: AppraisalStatus;
};
export type Appraisals = Omit<GeneratedAppraisals, 'appraisal_status'> & Record & WithAppraisalStatus;
export type Appraisal = Omit<GeneratedAppraisal, 'appraisal_status'> & Record & WithAppraisalStatus;
export type AppraisalEvent = Appraisals & {
  start: Date;
  end: Date;
  allDay: boolean;
  title: string;
  type: AppraisalEventType;
};

export enum AppraisalEventType {
  Inspection = 'inspection_date',
  Due = 'due_date',
  TASK_DUE = 'task_description',
  IN_PROGRESS = 'in_progress',
}

export interface AppraisalWithCoordinates extends Appraisals {
  coordinates: [number, number];
}

export type AppraisalActivities = Appraisal_Activities & Record;

export type MessageThread = Message_Thread & Record;

export type Message = MessagesType & Record;

export interface Participant {
  email: string;
  full_name: string;
  subscribed: boolean;
  assignee_role: string;
  subscribed_at: string;
  participant_id: string;
  participant_type: string;
  contact_type?: string;
}
