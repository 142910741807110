import {
  Box,
  Card,
  Chip,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import DirectionsIcon from '@material-ui/icons/Directions';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TextInput, useDataProvider } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';

import AutocompleteInput from 'shared/components/AutocompleteInput';
import { DateInput } from 'shared/components/Pickers';
import PlacesAutocomplete from 'shared/components/PlacesAutocomplete';
import { useAppraisalOptions } from 'shared/hooks/useAppraisalOptions';
import useFormPermissions from 'shared/hooks/useResourcePermissions';
import { abbreviateLastName, copyToClipboard, formatUSNumber } from 'shared/utils';
import getAppraisalPermission from 'views/Appraisal/permission';
import OwnerInput from '../OwnerInput';

import { styleLeft, styleRight } from 'shared/hooks/useEditFormStyle';

const getDefaultFields = (initialValue: boolean) => ({
  location_address: initialValue,
  'property.property_name': initialValue,
  'property.residential_ownership_type_id': initialValue,
  'property.residential_style_id': initialValue,
  'property.commercial_property_type_id': initialValue,
  'property.commercial_property_subtype_id': initialValue,
  'property.parcel_number': initialValue,
  'property.zoning': initialValue,
  'property.total_acres': initialValue,
  'property.subdivision': initialValue,
  'property.legal_description': initialValue,
  'property.year_built': initialValue,
  'property.commercial_buildings': initialValue,
  'property.commercial_floors': initialValue,
  'property.commercial_units': initialValue,
  'property.commercial_gross_area': initialValue,
  'property.residential_floors': initialValue,
  'property.residential_above_grade_bedrooms': initialValue,
  'property.residential_above_grade_bathrooms': initialValue,
  'property.residential_gross_living_area': initialValue,
  owner_contact_ids: initialValue,
});

function PropertySection() {
  const dataProvider = useDataProvider();
  const form = useForm();
  const formData = useFormState();
  const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down('xs'));

  const classes = styleLeft();
  const classesRight = styleRight();

  const [{ permissions }] = useFormPermissions({ getPermission: getAppraisalPermission });
  const [appraisalOptions] = useAppraisalOptions();

  const [edit, setEdit] = useState(false);
  const [propertyEdit, setPropertyEdit] = useState(false);
  const [ownerContacts, setOwnerContacts] = useState<any>([]);
  const [isOwnerChanged, setIsOwnerChanged] = useState<boolean>(false);
  const [ownerContactsView, setOwnerContactsView] = useState<any>([]);
  const [isMapToggled, setIsMapToggled] = useState<boolean>(false);
  const [fields, setFields] = useState<any>(getDefaultFields(false));

  const setAllFields = (val: boolean) => {
    setFields(getDefaultFields(val));
    setPropertyEdit(val);
  };

  const getMapHeight = () => {
    const el = document.getElementById('address-container');
    if (el) {
      const h = el.clientHeight;
      return `${280 - h}px`;
    }
    return '230px';
  };

  const getContactInformation = (id: any) => {
    let contacts: any = [];
    dataProvider.getMany('contacts', { ids: id }).then(({ data }: any) => {
      contacts = data?.map((item: any) => ({
        id: item?.id,
        full_name: item?.full_name,
        first_name: item?.first_name,
        last_name: item?.last_name,
        email: item?.email,
        phone: item?.phone_number,
        appraisalsCount: item?.appraisals_count,
        contact_type_id: item?.contact_type_id,
        type: item?.type,
      }));

      setOwnerContactsView(contacts);
    });
  };

  const initialOwnerData = useMemo(() => {
    return formData?.values?.owner_contact_ids || '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  useEffect(() => {
    // if (formData?.values?.owner_contact_ids?.length !== 0) {
    initialOwnerData && getContactInformation(initialOwnerData);
    // } else setOwnerContactsView([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialOwnerData]);

  const isFormDirty = (): boolean => !!Object.keys(formData.dirtyFields).length;
  const save = useCallback(debounce(form.submit, 0), []);

  return (
    <Card variant="outlined" classes={{ root: `${classesRight.card} ${classes.relative}` }}>
      <Typography classes={{ root: `${classesRight.heading} ${classesRight.heading500} ${classesRight.fontLarge}` }}>
        Property
      </Typography>
      {(isFormDirty() || isOwnerChanged) && propertyEdit && (
        <IconButton
          classes={{ root: classes.saveActionButton }}
          edge="end"
          onClick={() => {
            form.change('owner_contact_ids', ownerContacts);
            save();
            isOwnerChanged && getContactInformation(ownerContacts);
            setEdit(false);
            setAllFields(false);
            setIsOwnerChanged(false);
          }}
        >
          <SaveIcon classes={{ root: classesRight.icon }} />
        </IconButton>
      )}
      {permissions.edit && !propertyEdit && (
        <IconButton
          classes={{ root: classes.notesEditButton }}
          edge="end"
          disabled={edit}
          onClick={() => setAllFields(true)}
        >
          <EditIcon classes={{ root: classesRight.icon }} />
        </IconButton>
      )}
      {propertyEdit && (
        <IconButton classes={{ root: classes.notesEditButtonClose }} edge="end" onClick={() => setAllFields(false)}>
          <CloseIcon
            classes={{ root: classesRight.icon }}
            onClick={() => {
              setIsOwnerChanged(false);
              Object.keys(formData.dirtyFields).forEach((field: string) => {
                const [a, b] = field.split('.');
                if (a && b) {
                  form.change(field, formData.initialValues[a][b]);
                } else {
                  form.change(field, formData.initialValues[a]);
                }
              });
            }}
          />
        </IconButton>
      )}
      <Divider classes={{ root: classesRight.dividerLast }}></Divider>
      <Box className={classes.formContainer}>
        <Grid container direction={isSmallScreen ? 'column-reverse' : 'row'} alignItems="flex-start">
          <Grid container item lg={6} md={6} xs={12} sm={12} style={{ borderRight: 'solid 0.5px rgba(0, 0, 0, 0.06)' }}>
            <Box style={{ lineHeight: '5px' }}>&nbsp;</Box>
            <Box className={classesRight.flexBox}>
              <Typography classes={{ root: `${classesRight.leftHeading} ${classesRight.heading400}` }}>Name</Typography>
              <Box className={classesRight.inputContainer} style={{ width: '70%', paddingRight: '8px' }}>
                <TextInput
                  size="small"
                  color="secondary"
                  InputProps={{
                    placeholder: 'Click pencil to edit',
                    classes: {
                      root: `${classesRight.cssOutlinedInput} ${classesRight.inputFontWeight} ${fields['property.property_name']
                        ? classesRight.cssOutlinedActive
                        : classesRight.cssOutlinedDisabled
                        }`,
                      focused: classesRight.cssFocused,
                      notchedOutline: classesRight.notchedOutline,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          className={classesRight.fieldEditBtn}
                          edge="end"
                          disabled={fields['property.property_name'] && !formData.dirtyFields['property.property_name']}
                        >
                          {fields['property.property_name'] && formData.dirtyFields['property.property_name'] && (
                            <SaveIcon fontSize="small" classes={{ root: classesRight.icon }} onClick={() => save()} />
                          )}
                          &nbsp;
                          {fields['property.property_name'] && formData.dirtyFields['property.property_name'] && (
                            <CloseIcon
                              fontSize="small"
                              classes={{ root: classesRight.icon }}
                              onClick={() =>
                                form.change('property.property_name', formData.initialValues?.property?.property_name)
                              }
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  FormHelperTextProps={{ style: { display: 'none' } }}
                  variant="outlined"
                  disabled={!permissions.edit || !fields['property.property_name']}
                  fullWidth
                  label=""
                  source="property.property_name"
                />
              </Box>
            </Box>

            <Box
              p={1}
              style={{ width: '100%', marginLeft: '7px' }}
              onClick={(e: any) => copyToClipboard(formData.values?.property?.location_address, e, propertyEdit)}
            >
              <PlacesAutocomplete
                defaultValue="Click pencil to edit"
                fullWidth
                height={getMapHeight()}
                showLabel={true}
                size="small"
                shrink={true}
                color="secondary"
                variant="outlined"
                prefix="property"
                InputProps={{
                  placeholder: 'Click pencil to edit',
                  classes: {
                    root: `${classesRight.cssOutlinedInput} ${classesRight.inputFontSize} ${classesRight.addressInput
                      } ${fields.location_address ? classesRight.cssOutlinedActive : classesRight.cssOutlinedDisabled}`,
                    focused: classesRight.cssFocused,
                    notchedOutline: classesRight.notchedOutline,
                  },
                  endAdornment: (
                    <InputAdornment position="end" style={{ position: 'absolute', right: '-5px', top: '34px' }}>
                      <IconButton
                        size="small"
                        className={classesRight.fieldEditBtn}
                        edge="end"
                        disabled={fields.location_address && !formData.dirtyFields['property.location_address']}
                      >
                        {fields.location_address && formData.dirtyFields['property.location_address'] && (
                          <SaveIcon fontSize="small" classes={{ root: classesRight.icon }} onClick={() => save()} />
                        )}
                        &nbsp;
                        {fields.location_address && formData.dirtyFields['property.location_address'] && (
                          <CloseIcon
                            fontSize="small"
                            classes={{ root: classesRight.icon }}
                            onClick={() =>
                              form.change(
                                'property.location_address',
                                formData.initialValues?.property?.location_address,
                              )
                            }
                          />
                        )}
                        &nbsp;
                        <Box display={'flex'} flexDirection={'column'}>
                          {!fields.location_address && (
                            <DirectionsIcon
                              fontSize="small"
                              classes={{ root: classesRight.icon }}
                              onClick={() => {
                                const newWindow = window.open(
                                  `https://google.com/maps/place/${formData.values?.property?.location_address}`,
                                  '_blank',
                                  'noopener,noreferrer',
                                );
                                if (newWindow) newWindow.opener = null;
                              }}
                              style={{ paddingBottom: '5px' }}
                            />
                          )}
                          {isMapToggled ? (
                            <ExpandLess onClick={() => setIsMapToggled(false)} fontSize="small" />
                          ) : (
                            <ExpandMore
                              onClick={() => setIsMapToggled(true)}
                              fontSize="small"
                              classes={{ root: classesRight.icon }}
                            />
                          )}
                        </Box>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  classes: {
                    root: `${classesRight.inputLabelNone}`,
                  },
                }}
                FormHelperTextProps={{ style: { display: 'none' } }}
                autoFocus={false}
                label=" "
                isRequiredOverride={true}
                disabled={!permissions.edit || !fields.location_address}
                isRequired={false}
                isMapVisible={false}
                multiline
                rows={10}
                allowToggleMap={false}
                isMapToggled={isMapToggled}
                isEditPage={true}
              />
            </Box>

            <Box className={classesRight.flexBox}>
              <Typography classes={{ root: `${classesRight.leftHeading} ${classesRight.heading400}` }}>
                Subdivision
                {/* <Typography>Lot, Legal</Typography> */}
              </Typography>
              <Box pr={2} className={classesRight.inputContainer} style={{ width: '70%', paddingRight: '8px' }}>
                <TextInput
                  size="small"
                  color="secondary"
                  InputProps={{
                    placeholder: 'Click pencil to edit',
                    classes: {
                      root: `${classesRight.cssOutlinedInput} ${classesRight.inputFontWeight} ${fields['property.subdivision']
                        ? classesRight.cssOutlinedActive
                        : classesRight.cssOutlinedDisabled
                        }`,
                      focused: classesRight.cssFocused,
                      notchedOutline: classesRight.notchedOutline,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          className={classesRight.fieldEditBtn}
                          edge="end"
                          disabled={fields['property.subdivision'] && !formData.dirtyFields['property.subdivision']}
                        >
                          {fields['property.subdivision'] && formData.dirtyFields['property.subdivision'] && (
                            <SaveIcon fontSize="small" classes={{ root: classesRight.icon }} onClick={() => save()} />
                          )}
                          &nbsp;
                          {fields['property.subdivision'] && formData.dirtyFields['property.subdivision'] && (
                            <CloseIcon
                              fontSize="small"
                              classes={{ root: classesRight.icon }}
                              onClick={() =>
                                form.change('property.subdivision', formData.initialValues?.property?.subdivision)
                              }
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  FormHelperTextProps={{ style: { display: 'none' } }}
                  variant="outlined"
                  disabled={!permissions.edit || !fields['property.subdivision']}
                  fullWidth
                  label=""
                  source="property.subdivision"
                  multiline
                // rows={2}
                />
              </Box>
            </Box>
            <Box className={classesRight.flexBox}>
              <Typography classes={{ root: `${classesRight.leftHeading} ${classesRight.heading400}` }}>
                Parcel #
              </Typography>
              <Box className={classesRight.inputContainer} style={{ width: '70%', paddingRight: '8px' }}>
                <TextInput
                  size="small"
                  color="secondary"
                  InputProps={{
                    placeholder: 'Click pencil to edit',
                    classes: {
                      root: `${classesRight.cssOutlinedInput} ${classesRight.inputFontWeight} ${fields['property.parcel_number']
                        ? classesRight.cssOutlinedActive
                        : classesRight.cssOutlinedDisabled
                        }`,
                      focused: classesRight.cssFocused,
                      notchedOutline: classesRight.notchedOutline,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          className={classesRight.fieldEditBtn}
                          edge="end"
                          disabled={fields['property.parcel_number'] && !formData.dirtyFields['property.parcel_number']}
                        >
                          {fields['property.parcel_number'] && formData.dirtyFields['property.parcel_number'] && (
                            <SaveIcon fontSize="small" classes={{ root: classesRight.icon }} onClick={() => save()} />
                          )}
                          &nbsp;
                          {fields['property.parcel_number'] && formData.dirtyFields['property.parcel_number'] && (
                            <CloseIcon
                              fontSize="small"
                              classes={{ root: classesRight.icon }}
                              onClick={() =>
                                form.change('property.parcel_number', formData.initialValues?.property?.parcel_number)
                              }
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  FormHelperTextProps={{ style: { display: 'none' } }}
                  variant="outlined"
                  disabled={!permissions.edit || !fields['property.parcel_number']}
                  fullWidth
                  label=""
                  source="property.parcel_number"
                />
              </Box>
            </Box>
            <Box className={classesRight.flexBox}>
              <Typography classes={{ root: `${classesRight.leftHeading} ${classesRight.heading400}` }}>
                Zoning
              </Typography>
              <Box className={classesRight.inputContainer} style={{ width: '70%', paddingRight: '8px' }}>
                <TextInput
                  size="small"
                  color="secondary"
                  InputProps={{
                    placeholder: 'Click pencil to edit',
                    classes: {
                      root: `${classesRight.cssOutlinedInput} ${classesRight.inputFontWeight} ${fields['property.zoning'] ? classesRight.cssOutlinedActive : classesRight.cssOutlinedDisabled
                        }`,
                      focused: classesRight.cssFocused,
                      notchedOutline: classesRight.notchedOutline,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          className={classesRight.fieldEditBtn}
                          edge="end"
                          disabled={fields['property.zoning'] && !formData.dirtyFields['property.zoning']}
                        >
                          {fields['property.zoning'] && formData.dirtyFields['property.zoning'] && (
                            <SaveIcon fontSize="small" classes={{ root: classesRight.icon }} onClick={() => save()} />
                          )}
                          &nbsp;
                          {fields['property.zoning'] && formData.dirtyFields['property.zoning'] && (
                            <CloseIcon
                              fontSize="small"
                              classes={{ root: classesRight.icon }}
                              onClick={() => form.change('property.zoning', formData.initialValues?.property?.zoning)}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  FormHelperTextProps={{ style: { display: 'none' } }}
                  variant="outlined"
                  disabled={!permissions.edit || !fields['property.zoning']}
                  fullWidth
                  label=""
                  source="property.zoning"
                />
              </Box>
            </Box>
            <Box className={classesRight.flexBox}>
              <Typography classes={{ root: `${classesRight.leftHeading} ${classesRight.heading400}` }}>
                Acres
              </Typography>
              <Box className={classesRight.inputContainer} style={{ width: '70%', paddingRight: '8px' }}>
                <TextInput
                  size="small"
                  color="secondary"
                  InputProps={{
                    placeholder: 'Click pencil to edit',
                    classes: {
                      root: `${classesRight.cssOutlinedInput} ${classesRight.inputFontWeight} ${fields['property.total_acres']
                        ? classesRight.cssOutlinedActive
                        : classesRight.cssOutlinedDisabled
                        }`,
                      focused: classesRight.cssFocused,
                      notchedOutline: classesRight.notchedOutline,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          className={classesRight.fieldEditBtn}
                          edge="end"
                          disabled={fields['property.total_acres'] && !formData.dirtyFields['property.total_acres']}
                        >
                          {fields['property.total_acres'] && formData.dirtyFields['property.total_acres'] && (
                            <SaveIcon fontSize="small" classes={{ root: classesRight.icon }} onClick={() => save()} />
                          )}
                          &nbsp;
                          {fields['property.total_acres'] && formData.dirtyFields['property.total_acres'] && (
                            <CloseIcon
                              fontSize="small"
                              classes={{ root: classesRight.icon }}
                              onClick={() =>
                                form.change('property.total_acres', formData.initialValues?.property?.total_acres)
                              }
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  FormHelperTextProps={{ style: { display: 'none' } }}
                  variant="outlined"
                  disabled={!permissions.edit || !fields['property.total_acres']}
                  fullWidth
                  label=""
                  source="property.total_acres"
                />
              </Box>
            </Box>
            <Box className={classesRight.flexBoxAuto}>
              <Typography classes={{ root: `${classesRight.leftHeading} ${classesRight.heading400}` }}>
                Legal
              </Typography>
              <Box className={classesRight.inputContainer} style={{ width: '70%', paddingRight: '8px' }}>
                <TextInput
                  size="small"
                  color="secondary"
                  InputProps={{
                    placeholder: 'Click pencil to edit',
                    classes: {
                      root: `${classesRight.cssOutlinedInput} ${classesRight.inputFontWeight} ${fields['property.legal_description']
                        ? classesRight.cssOutlinedActive
                        : classesRight.cssOutlinedDisabled
                        }`,
                      focused: classesRight.cssFocused,
                      notchedOutline: classesRight.notchedOutline,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          className={classesRight.fieldEditBtn}
                          edge="end"
                          disabled={
                            fields['property.legal_description'] && !formData.dirtyFields['property.legal_description']
                          }
                        >
                          {fields['property.legal_description'] &&
                            formData.dirtyFields['property.legal_description'] && (
                              <SaveIcon fontSize="small" classes={{ root: classesRight.icon }} onClick={() => save()} />
                            )}
                          &nbsp;
                          {fields['property.legal_description'] &&
                            formData.dirtyFields['property.legal_description'] && (
                              <CloseIcon
                                fontSize="small"
                                classes={{ root: classesRight.icon }}
                                onClick={() =>
                                  form.change(
                                    'property.legal_description',
                                    formData.initialValues?.property?.legal_description,
                                  )
                                }
                              />
                            )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  FormHelperTextProps={{ style: { display: 'none' } }}
                  variant="outlined"
                  disabled={!permissions.edit || !fields['property.legal_description']}
                  fullWidth
                  label=""
                  source="property.legal_description"
                  multiline
                />
              </Box>
            </Box>
          </Grid>
          <Grid container item lg={6} md={6} xs={12} sm={12} style={{ borderLeft: 'solid 0.5px rgba(0, 0, 0, 0.06)' }}>
            <Box style={{ lineHeight: '5px' }}>&nbsp;</Box>
            {formData.values.property_type_id === 1 && (
              <>
                <Box className={classesRight.flexBox}>
                  <Typography classes={{ root: `${classesRight.heading} ${classesRight.heading400}` }}>
                    Ownership
                  </Typography>
                  <Box pr={2} className={classesRight.inputContainer}>
                    <AutocompleteInput
                      label=""
                      variant="outlined"
                      fullWidth
                      disabled={!permissions.edit || !fields['property.residential_ownership_type_id']}
                      source="property.residential_ownership_type_id"
                      optionText="type"
                      choices={appraisalOptions.data?.residentialOwnershipTypes ?? []}
                      css={{
                        popupIndicator: classesRight.popupIndicator,
                        popupIndicatorOpen: classesRight.popupIndicatorOpen,
                      }}
                      options={{
                        InputProps: {
                          placeholder: 'Click pencil to edit',
                          classes: {
                            root: `${classesRight.cssOutlinedInput} ${classesRight.inputFontSize} ${fields['property.residential_ownership_type_id']
                              ? classesRight.cssOutlinedActive
                              : classesRight.cssOutlinedDisabled
                              }`,
                            focused: classesRight.cssFocused,
                            notchedOutline: classesRight.notchedOutline,
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                size="small"
                                className={classesRight.fieldEditBtn}
                                edge="end"
                                disabled={
                                  fields['property.residential_ownership_type_id'] &&
                                  !formData.dirtyFields['property.residential_ownership_type_id']
                                }
                              >
                                {fields['property.residential_ownership_type_id'] &&
                                  formData.dirtyFields['property.residential_ownership_type_id'] && (
                                    <SaveIcon
                                      fontSize="small"
                                      classes={{ root: classesRight.icon }}
                                      onClick={() => save()}
                                    />
                                  )}
                                &nbsp;
                                {fields['property.residential_ownership_type_id'] &&
                                  formData.dirtyFields['property.residential_ownership_type_id'] && (
                                    <CloseIcon
                                      fontSize="small"
                                      classes={{ root: classesRight.icon }}
                                      onClick={() =>
                                        form.change(
                                          'property.residential_ownership_type_id',
                                          formData.initialValues?.property?.residential_ownership_type_id,
                                        )
                                      }
                                    />
                                  )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        },
                        InputLabelProps: {
                          classes: {
                            root: `${classesRight.inputFontSize}`,
                          },
                        },
                      }}
                      FormHelperTextProps={{ style: { display: 'none' } }}
                    />
                  </Box>
                </Box>
                <Box className={classesRight.flexBox}>
                  <Typography classes={{ root: `${classesRight.heading} ${classesRight.heading400}` }}>
                    Style
                  </Typography>
                  <Box pr={2} className={classesRight.inputContainer}>
                    <AutocompleteInput
                      label=""
                      fullWidth
                      variant="outlined"
                      source="property.residential_style_id"
                      disabled={!permissions.edit || !fields['property.residential_style_id']}
                      optionText="style"
                      choices={appraisalOptions.data?.residentialStyles ?? []}
                      css={{
                        popupIndicator: classesRight.popupIndicator,
                        popupIndicatorOpen: classesRight.popupIndicatorOpen,
                      }}
                      options={{
                        InputProps: {
                          placeholder: 'Click pencil to edit',
                          classes: {
                            root: `${classesRight.cssOutlinedInput} ${classesRight.inputFontSize} ${fields['property.residential_style_id']
                              ? classesRight.cssOutlinedActive
                              : classesRight.cssOutlinedDisabled
                              }`,
                            focused: classesRight.cssFocused,
                            notchedOutline: classesRight.notchedOutline,
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                size="small"
                                className={classesRight.fieldEditBtn}
                                edge="end"
                                disabled={
                                  fields['property.residential_style_id'] &&
                                  !formData.dirtyFields['property.residential_style_id']
                                }
                              >
                                {fields['property.residential_style_id'] &&
                                  formData.dirtyFields['property.residential_style_id'] && (
                                    <SaveIcon
                                      fontSize="small"
                                      classes={{ root: classesRight.icon }}
                                      onClick={() => save()}
                                    />
                                  )}
                                &nbsp;
                                {fields['property.residential_style_id'] &&
                                  formData.dirtyFields['property.residential_style_id'] && (
                                    <CloseIcon
                                      fontSize="small"
                                      classes={{ root: classesRight.icon }}
                                      onClick={() =>
                                        form.change(
                                          'property.residential_style_id',
                                          formData.initialValues?.property?.residential_style_id,
                                        )
                                      }
                                    />
                                  )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        },
                        InputLabelProps: {
                          classes: {
                            root: `${classesRight.inputFontSize}`,
                          },
                        },
                      }}
                      FormHelperTextProps={{ style: { display: 'none' } }}
                    />
                  </Box>
                </Box>
              </>
            )}
            {formData.values.property_type_id === 2 && (
              <>
                <Box className={classesRight.flexBox}>
                  <Typography classes={{ root: `${classesRight.heading} ${classesRight.heading400}` }}>Type</Typography>
                  <Box pr={2} className={classesRight.inputContainer}>
                    <AutocompleteInput
                      choices={appraisalOptions.data?.commercialPropertyTypes ?? []}
                      label=""
                      variant="outlined"
                      fullWidth
                      disabled={!permissions.edit || !fields['property.commercial_property_type_id']}
                      source="property.commercial_property_type_id"
                      optionText="type"
                      FormHelperTextProps={{ style: { display: 'none' } }}
                      css={{
                        popupIndicator: classesRight.popupIndicator,
                        popupIndicatorOpen: classesRight.popupIndicatorOpen,
                      }}
                      options={{
                        InputProps: {
                          placeholder: 'Click pencil to edit',
                          classes: {
                            root: `${classesRight.cssOutlinedInput} ${classesRight.inputFontSize} ${fields['property.commercial_property_type_id']
                              ? classesRight.cssOutlinedActive
                              : classesRight.cssOutlinedDisabled
                              }`,
                            focused: classesRight.cssFocused,
                            notchedOutline: classesRight.notchedOutline,
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                size="small"
                                className={classesRight.fieldEditBtn}
                                edge="end"
                                disabled={
                                  fields['property.commercial_property_type_id'] &&
                                  !formData.dirtyFields['property.commercial_property_type_id']
                                }
                              >
                                {fields['property.commercial_property_type_id'] &&
                                  formData.dirtyFields['property.commercial_property_type_id'] && (
                                    <SaveIcon
                                      fontSize="small"
                                      classes={{ root: classesRight.icon }}
                                      onClick={() => save()}
                                    />
                                  )}
                                &nbsp;
                                {fields['property.commercial_property_type_id'] &&
                                  formData.dirtyFields['property.commercial_property_type_id'] && (
                                    <CloseIcon
                                      fontSize="small"
                                      classes={{ root: classesRight.icon }}
                                      onClick={() =>
                                        form.change(
                                          'property.commercial_property_type_id',
                                          formData.initialValues?.property?.commercial_property_type_id,
                                        )
                                      }
                                    />
                                  )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        },
                        InputLabelProps: {
                          classes: {
                            root: `${classesRight.inputFontSize}`,
                          },
                        },
                      }}
                    />
                  </Box>
                </Box>
                <Box className={classesRight.flexBox}>
                  <Typography classes={{ root: `${classesRight.heading} ${classesRight.heading400}` }}>
                    Subtype
                  </Typography>
                  <Box pr={2} className={classesRight.inputContainer}>
                    <AutocompleteInput
                      label=""
                      fullWidth
                      disabled={!permissions.edit || !fields['property.commercial_property_subtype_id']}
                      source="property.commercial_property_subtype_id"
                      optionText="subtype"
                      variant="outlined"
                      FormHelperTextProps={{ style: { display: 'none' } }}
                      choices={
                        appraisalOptions.data?.commercialPropertySubTypes.filter(
                          (e: any) =>
                            e.commercial_property_type_id === formData.values?.property?.commercial_property_type_id ||
                            e.commercial_property_type_id === formData.values.commercial_property_type_id,
                        ) ?? []
                      }
                      css={{
                        popupIndicator: classesRight.popupIndicator,
                        popupIndicatorOpen: classesRight.popupIndicatorOpen,
                      }}
                      options={{
                        InputProps: {
                          placeholder: 'Click pencil to edit',
                          classes: {
                            root: `${classesRight.cssOutlinedInput} ${classesRight.inputFontSize} ${fields['property.commercial_property_subtype_id']
                              ? classesRight.cssOutlinedActive
                              : classesRight.cssOutlinedDisabled
                              }`,
                            focused: classesRight.cssFocused,
                            notchedOutline: classesRight.notchedOutline,
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                size="small"
                                className={classesRight.fieldEditBtn}
                                edge="end"
                                disabled={
                                  fields['property.commercial_property_subtype_id'] &&
                                  !formData.dirtyFields['property.commercial_property_subtype_id']
                                }
                              >
                                {fields['property.commercial_property_subtype_id'] &&
                                  formData.dirtyFields['property.commercial_property_subtype_id'] && (
                                    <SaveIcon
                                      fontSize="small"
                                      classes={{ root: classesRight.icon }}
                                      onClick={() => save()}
                                    />
                                  )}
                                &nbsp;
                                {fields['property.commercial_property_subtype_id'] &&
                                  formData.dirtyFields['property.commercial_property_subtype_id'] && (
                                    <CloseIcon
                                      fontSize="small"
                                      classes={{ root: classesRight.icon }}
                                      onClick={() =>
                                        form.change(
                                          'property.commercial_property_subtype_id',
                                          formData.initialValues?.property?.commercial_property_subtype_id,
                                        )
                                      }
                                    />
                                  )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        },
                        InputLabelProps: {
                          classes: {
                            root: `${classesRight.inputFontSize}`,
                          },
                        },
                      }}
                    />
                  </Box>
                </Box>
              </>
            )}
            <Divider classes={{ root: classesRight.dividerEnd }} style={{ width: '100%' }}></Divider>
            <Box className={classesRight.flexBox}>
              <Typography classes={{ root: `${classesRight.heading} ${classesRight.heading400}` }}>Built</Typography>
              <Box pr={2} className={classesRight.inputContainer}>
                <DateInput
                  onClick={(e: any) => copyToClipboard(formData?.values?.property?.year_built, e, propertyEdit)}
                  size="small"
                  color="secondary"
                  InputProps={{
                    placeholder: 'Click pencil to edit',
                    classes: {
                      root: `${classesRight.cssOutlinedInput} ${classesRight.inputFontWeight} ${fields['property.year_built']
                        ? classesRight.cssOutlinedActive
                        : classesRight.cssOutlinedDisabled
                        }`,
                      focused: classesRight.cssFocused,
                      notchedOutline: classesRight.notchedOutline,
                    },
                  }}
                  FormHelperTextProps={{ style: { display: 'none' } }}
                  variant="outlined"
                  disabled={!permissions.edit || !fields['property.year_built']}
                  fullWidth
                  label=""
                  source="property.year_built"
                />
                <IconButton
                  size="small"
                  className={`${classesRight.fieldEditBtn} ${classesRight.fieldEditDateBtn}`}
                  edge="end"
                  disabled={fields['property.year_built'] && !formData.dirtyFields['property.year_built']}
                >
                  {fields['property.year_built'] && formData.dirtyFields['property.year_built'] && (
                    <SaveIcon fontSize="small" classes={{ root: classesRight.icon }} onClick={() => save()} />
                  )}
                  &nbsp;
                  {fields['property.year_built'] && formData.dirtyFields['property.year_built'] && (
                    <CloseIcon
                      fontSize="small"
                      classes={{ root: classesRight.icon }}
                      onClick={() => form.change('property.year_built', formData?.initialValues?.property?.year_built)}
                    />
                  )}
                </IconButton>
              </Box>
            </Box>
            {formData?.values?.property_type_id === 2 && (
              <Box className={classesRight.flexBox}>
                <Typography classes={{ root: `${classesRight.heading} ${classesRight.heading400}` }}>
                  Buildings
                </Typography>
                <Box pr={2} className={classesRight.inputContainer}>
                  <TextInput
                    size="small"
                    color="secondary"
                    InputProps={{
                      placeholder: 'Click pencil to edit',
                      classes: {
                        root: `${classesRight.cssOutlinedInput} ${classesRight.inputFontWeight} ${fields['property.commercial_buildings']
                          ? classesRight.cssOutlinedActive
                          : classesRight.cssOutlinedDisabled
                          }`,
                        focused: classesRight.cssFocused,
                        notchedOutline: classesRight.notchedOutline,
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            className={classesRight.fieldEditBtn}
                            edge="end"
                            disabled={
                              fields['property.commercial_buildings'] &&
                              !formData.dirtyFields['property.commercial_buildings']
                            }
                          >
                            {fields['property.commercial_buildings'] &&
                              formData.dirtyFields['property.commercial_buildings'] && (
                                <SaveIcon
                                  fontSize="small"
                                  classes={{ root: classesRight.icon }}
                                  onClick={() => save()}
                                />
                              )}
                            &nbsp;
                            {fields['property.commercial_buildings'] &&
                              formData.dirtyFields['property.commercial_buildings'] && (
                                <CloseIcon
                                  fontSize="small"
                                  classes={{ root: classesRight.icon }}
                                  onClick={() =>
                                    form.change(
                                      'property.commercial_buildings',
                                      formData.initialValues?.property?.commercial_buildings,
                                    )
                                  }
                                />
                              )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    FormHelperTextProps={{ style: { display: 'none' } }}
                    variant="outlined"
                    disabled={!permissions.edit || !fields['property.commercial_buildings']}
                    fullWidth
                    label=""
                    source="property.commercial_buildings"
                    format={(value: any) => (value ? formatUSNumber(value) : '')}
                  />
                </Box>
              </Box>
            )}
            <Box className={classesRight.flexBox}>
              <Typography classes={{ root: `${classesRight.heading} ${classesRight.heading400}` }}>Floors</Typography>
              <Box pr={2} className={classesRight.inputContainer}>
                <TextInput
                  size="small"
                  color="secondary"
                  InputProps={{
                    placeholder: 'Click pencil to edit',
                    classes: {
                      root: `${classesRight.cssOutlinedInput} ${classesRight.inputFontWeight} ${fields['property.commercial_floors']
                        ? classesRight.cssOutlinedActive
                        : classesRight.cssOutlinedDisabled
                        }`,
                      focused: classesRight.cssFocused,
                      notchedOutline: classesRight.notchedOutline,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          className={classesRight.fieldEditBtn}
                          edge="end"
                          disabled={
                            fields['property.commercial_floors'] && !formData.dirtyFields['property.commercial_floors']
                          }
                        >
                          {fields['property.commercial_floors'] &&
                            formData.dirtyFields['property.commercial_floors'] && (
                              <SaveIcon fontSize="small" classes={{ root: classesRight.icon }} onClick={() => save()} />
                            )}
                          &nbsp;
                          {fields['property.commercial_floors'] &&
                            formData.dirtyFields['property.commercial_floors'] && (
                              <CloseIcon
                                fontSize="small"
                                classes={{ root: classesRight.icon }}
                                onClick={() =>
                                  form.change(
                                    'property.commercial_floors',
                                    formData.initialValues?.property?.commercial_floors,
                                  )
                                }
                              />
                            )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  FormHelperTextProps={{ style: { display: 'none' } }}
                  variant="outlined"
                  disabled={!permissions.edit || !fields['property.commercial_floors']}
                  fullWidth
                  label=""
                  source="property.commercial_floors"
                  format={(value: any) => (value ? formatUSNumber(value) : '')}
                />
              </Box>
            </Box>
            {formData?.values?.property_type_id === 2 && (
              <>
                <Box className={classesRight.flexBox}>
                  <Typography classes={{ root: `${classesRight.heading} ${classesRight.heading400}` }}>
                    Units
                  </Typography>
                  <Box pr={2} className={classesRight.inputContainer}>
                    <TextInput
                      size="small"
                      color="secondary"
                      InputProps={{
                        placeholder: 'Click pencil to edit',
                        classes: {
                          root: `${classesRight.cssOutlinedInput} ${classesRight.inputFontWeight} ${fields['property.commercial_units']
                            ? classesRight.cssOutlinedActive
                            : classesRight.cssOutlinedDisabled
                            }`,
                          focused: classesRight.cssFocused,
                          notchedOutline: classesRight.notchedOutline,
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              size="small"
                              className={classesRight.fieldEditBtn}
                              edge="end"
                              disabled={
                                fields['property.commercial_units'] &&
                                !formData.dirtyFields['property.commercial_units']
                              }
                            >
                              {fields['property.commercial_units'] &&
                                formData.dirtyFields['property.commercial_units'] && (
                                  <SaveIcon
                                    fontSize="small"
                                    classes={{ root: classesRight.icon }}
                                    onClick={() => save()}
                                  />
                                )}
                              &nbsp;
                              {fields['property.commercial_units'] &&
                                formData.dirtyFields['property.commercial_units'] && (
                                  <CloseIcon
                                    fontSize="small"
                                    classes={{ root: classesRight.icon }}
                                    onClick={() =>
                                      form.change(
                                        'property.commercial_units',
                                        formData.initialValues?.property?.commercial_units,
                                      )
                                    }
                                  />
                                )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      FormHelperTextProps={{ style: { display: 'none' } }}
                      variant="outlined"
                      disabled={!permissions.edit || !fields['property.commercial_units']}
                      fullWidth
                      label=""
                      source="property.commercial_units"
                      format={(value: any) => (value ? formatUSNumber(value) : '')}
                    />
                  </Box>
                </Box>
                <Box className={classesRight.flexBox}>
                  <Typography classes={{ root: `${classesRight.heading} ${classesRight.heading400}` }}>
                    Total Area
                  </Typography>
                  <Box pr={2} className={classesRight.inputContainer}>
                    <TextInput
                      size="small"
                      color="secondary"
                      InputProps={{
                        placeholder: 'Click pencil to edit',
                        classes: {
                          root: `${classesRight.cssOutlinedInput} ${classesRight.inputFontWeight} ${fields['property.commercial_gross_area']
                            ? classesRight.cssOutlinedActive
                            : classesRight.cssOutlinedDisabled
                            }`,
                          focused: classesRight.cssFocused,
                          notchedOutline: classesRight.notchedOutline,
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              size="small"
                              className={classesRight.fieldEditBtn}
                              edge="end"
                              disabled={
                                fields['property.commercial_gross_area'] &&
                                !formData.dirtyFields['property.commercial_gross_area']
                              }
                            >
                              {fields['property.commercial_gross_area'] &&
                                formData.dirtyFields['property.commercial_gross_area'] && (
                                  <SaveIcon
                                    fontSize="small"
                                    classes={{ root: classesRight.icon }}
                                    onClick={() => save()}
                                  />
                                )}
                              &nbsp;
                              {fields['property.commercial_gross_area'] &&
                                formData.dirtyFields['property.commercial_gross_area'] && (
                                  <CloseIcon
                                    fontSize="small"
                                    classes={{ root: classesRight.icon }}
                                    onClick={() =>
                                      form.change(
                                        'property.commercial_gross_area',
                                        formData.initialValues?.property?.commercial_gross_area,
                                      )
                                    }
                                  />
                                )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      FormHelperTextProps={{ style: { display: 'none' } }}
                      variant="outlined"
                      disabled={!permissions.edit || !fields['property.commercial_gross_area']}
                      fullWidth
                      label=""
                      source="property.commercial_gross_area"
                      format={(value: any) => (value ? formatUSNumber(value) : '')}
                    />
                  </Box>
                </Box>
              </>
            )}
            {formData?.values?.property_type_id === 1 && (
              <>
                <Box className={classesRight.flexBox}>
                  <Typography classes={{ root: `${classesRight.heading} ${classesRight.heading400}` }}>
                    Bedrooms
                  </Typography>
                  <Box pr={2} className={classesRight.inputContainer}>
                    <TextInput
                      size="small"
                      color="secondary"
                      InputProps={{
                        placeholder: 'Click pencil to edit',
                        classes: {
                          root: `${classesRight.cssOutlinedInput} ${classesRight.inputFontWeight} ${fields['property.residential_above_grade_bedrooms']
                            ? classesRight.cssOutlinedActive
                            : classesRight.cssOutlinedDisabled
                            }`,
                          focused: classesRight.cssFocused,
                          notchedOutline: classesRight.notchedOutline,
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              size="small"
                              className={classesRight.fieldEditBtn}
                              edge="end"
                              disabled={
                                fields['property.residential_above_grade_bedrooms'] &&
                                !formData.dirtyFields['property.residential_above_grade_bedrooms']
                              }
                            >
                              {fields['property.residential_above_grade_bedrooms'] &&
                                formData.dirtyFields['property.residential_above_grade_bedrooms'] && (
                                  <SaveIcon
                                    fontSize="small"
                                    classes={{ root: classesRight.icon }}
                                    onClick={() => save()}
                                  />
                                )}
                              &nbsp;
                              {fields['property.residential_above_grade_bedrooms'] &&
                                formData.dirtyFields['property.residential_above_grade_bedrooms'] && (
                                  <CloseIcon
                                    fontSize="small"
                                    classes={{ root: classesRight.icon }}
                                    onClick={() =>
                                      form.change(
                                        'property.residential_above_grade_bedrooms',
                                        formData.initialValues?.property?.residential_above_grade_bedrooms,
                                      )
                                    }
                                  />
                                )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      FormHelperTextProps={{ style: { display: 'none' } }}
                      variant="outlined"
                      disabled={!permissions.edit || !fields['property.residential_above_grade_bedrooms']}
                      fullWidth
                      label=""
                      source="property.residential_above_grade_bedrooms"
                      format={(value: any) => (value ? formatUSNumber(value) : '')}
                    />
                  </Box>
                </Box>
                <Box className={classesRight.flexBox}>
                  <Typography classes={{ root: `${classesRight.heading} ${classesRight.heading400}` }}>
                    Bathrooms
                  </Typography>
                  <Box pr={2} className={classesRight.inputContainer}>
                    <TextInput
                      size="small"
                      color="secondary"
                      InputProps={{
                        placeholder: 'Click pencil to edit',
                        classes: {
                          root: `${classesRight.cssOutlinedInput} ${classesRight.inputFontWeight} ${fields['property.residential_above_grade_bathrooms']
                            ? classesRight.cssOutlinedActive
                            : classesRight.cssOutlinedDisabled
                            }`,
                          focused: classesRight.cssFocused,
                          notchedOutline: classesRight.notchedOutline,
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              size="small"
                              className={classesRight.fieldEditBtn}
                              edge="end"
                              disabled={
                                fields['property.residential_above_grade_bathrooms'] &&
                                !formData.dirtyFields['property.residential_above_grade_bathrooms']
                              }
                            >
                              {fields['property.residential_above_grade_bathrooms'] &&
                                formData.dirtyFields['property.residential_above_grade_bathrooms'] && (
                                  <SaveIcon
                                    fontSize="small"
                                    classes={{ root: classesRight.icon }}
                                    onClick={() => save()}
                                  />
                                )}
                              &nbsp;
                              {fields['property.residential_above_grade_bathrooms'] &&
                                formData.dirtyFields['property.residential_above_grade_bathrooms'] && (
                                  <CloseIcon
                                    fontSize="small"
                                    classes={{ root: classesRight.icon }}
                                    onClick={() =>
                                      form.change(
                                        'property.residential_above_grade_bathrooms',
                                        formData.initialValues?.property?.residential_above_grade_bathrooms,
                                      )
                                    }
                                  />
                                )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      FormHelperTextProps={{ style: { display: 'none' } }}
                      variant="outlined"
                      disabled={!permissions.edit || !fields['property.residential_above_grade_bathrooms']}
                      fullWidth
                      label=""
                      source="property.residential_above_grade_bathrooms"
                      format={(value: any) => (value ? formatUSNumber(value) : '')}
                    />
                  </Box>
                </Box>
                <Box className={classesRight.flexBox}>
                  <Typography classes={{ root: `${classesRight.heading} ${classesRight.heading400}` }}>
                    Total Area
                  </Typography>
                  <Box pr={2} className={classesRight.inputContainer}>
                    <TextInput
                      size="small"
                      color="secondary"
                      InputProps={{
                        placeholder: 'Click pencil to edit',
                        classes: {
                          root: `${classesRight.cssOutlinedInput} ${classesRight.inputFontWeight} ${fields['property.residential_gross_living_area']
                            ? classesRight.cssOutlinedActive
                            : classesRight.cssOutlinedDisabled
                            }`,
                          focused: classesRight.cssFocused,
                          notchedOutline: classesRight.notchedOutline,
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              size="small"
                              className={classesRight.fieldEditBtn}
                              edge="end"
                              disabled={
                                fields['property.residential_gross_living_area'] &&
                                !formData.dirtyFields['property.residential_gross_living_area']
                              }
                            >
                              {fields['property.residential_gross_living_area'] &&
                                formData.dirtyFields['property.residential_gross_living_area'] && (
                                  <SaveIcon
                                    fontSize="small"
                                    classes={{ root: classesRight.icon }}
                                    onClick={() => save()}
                                  />
                                )}
                              &nbsp;
                              {fields['property.residential_gross_living_area'] &&
                                formData.dirtyFields['property.residential_gross_living_area'] && (
                                  <CloseIcon
                                    fontSize="small"
                                    classes={{ root: classesRight.icon }}
                                    onClick={() =>
                                      form.change(
                                        'property.residential_gross_living_area',
                                        formData.initialValues?.property?.residential_gross_living_area,
                                      )
                                    }
                                  />
                                )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      FormHelperTextProps={{ style: { display: 'none' } }}
                      variant="outlined"
                      disabled={!permissions.edit || !fields['property.residential_gross_living_area']}
                      fullWidth
                      label=""
                      source="property.residential_gross_living_area"
                      format={(value: any) => (value ? formatUSNumber(value) : '')}
                    />
                  </Box>
                </Box>
              </>
            )}
            <Divider classes={{ root: classesRight.dividerEnd }} style={{ width: '100%' }}></Divider>
            <Box className={classesRight.flexBoxAuto}>
              <Typography classes={{ root: `${classesRight.heading} ${classesRight.heading400}` }}>Owners</Typography>
              {formData?.values?.owner_contact_ids?.length === 0 && !propertyEdit ? (
                <Box pr={2} style={{ paddingBottom: '12px' }} className={classesRight.inputContainer}>
                  <Typography classes={{ root: `${classesRight.nextTask} ${classesRight.disabledInput}` }}>
                    Click pencil to edit
                  </Typography>
                </Box>
              ) : (
                <Box
                  pr={2}
                  pb={2}
                  pt={1}
                  mb={formData?.values?.owner_contact_ids?.length !== 0 || propertyEdit ? 0 : 4}
                  className={classesRight.inputContainer}
                >
                  {propertyEdit ? (
                    <OwnerInput
                      owners={formData?.values?.owner_contact_ids ?? []}
                      setOwners={(owners: any) => {
                        const contacts = owners.map((item: any) => item.id);
                        setOwnerContacts(contacts);
                        setIsOwnerChanged(true);
                        // setOwnerContacts((prev: any[]) => ({ ...prev, owners }));
                      }}
                      isEditPage={true}
                    />
                  ) : ownerContactsView?.length ? (
                    ownerContactsView?.map((choice: any) => (
                      <Chip
                        key={choice?.id}
                        label={`${abbreviateLastName(
                          choice?.first_name + (choice?.last_name ? ' ' + choice?.last_name : ''),
                        )} (${choice?.type})`}
                        style={{
                          backgroundColor: '#e0e0e0',
                          color: 'rgba(0, 0, 0, 0.87)',
                          height: '32px',
                          margin: '3px',
                        }}
                      // onClick={() => redirect(`/appraisals/${formData?.values?.id}#contacts`)}
                      />
                    ))
                  ) : (
                    <Box pr={2} style={{ paddingBottom: '12px' }} className={classesRight.inputContainer}>
                      <Typography
                        classes={{ root: `${classesRight.nextTask} ${classesRight.disabledInput}` }}
                      ></Typography>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
}
export default PropertySection;
