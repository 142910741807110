import DateFnsUtils from '@date-io/date-fns';
import { Box, Divider, Grid, Typography } from '@material-ui/core';
import { AccessTime } from '@material-ui/icons';
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { Button, FieldTitle, TextInput } from 'react-admin';
import AutocompleteArrayInput from 'shared/components/AutocompleteArrayInput';

import AutocompleteInput from 'shared/components/AutocompleteInput';
import CurrencyInput from 'shared/components/CurrencyInput';
import { DateInput } from 'shared/components/Pickers';
import { useAppraisalOptions } from 'shared/hooks/useAppraisalOptions';
import AddExistingClientButton from 'views/Client/components/ClientBetterAdd/AddExistingClient';
import { styles } from '../../appraisalStyles';

interface Props {
  formData: any;
  setContactIds: Dispatch<any>;
  setClientId: Dispatch<any>;
  dateVal: MaterialUiPickersDate;
  setDateVal: Dispatch<SetStateAction<MaterialUiPickersDate>>;
  setReportFee: Dispatch<any>;
  setEngagementData: Dispatch<any>;
}

const pushOrRemove = (data: number[], num: number) => {
  const arr = [...data];
  const index = arr.indexOf(num);
  if (index === -1) {
    // Number doesn't exist in the array, so push it.
    arr.push(num);
  } else {
    // Number already exists in the array, so remove it.
    arr.splice(index, 1);
  }
  return arr;
};

const EngagementSection = ({
  formData,
  setClientId,
  setContactIds,
  setDateVal,
  dateVal,
  setReportFee,
  setEngagementData,
}: Props) => {
  const classes = styles();
  const [appraisalOptions] = useAppraisalOptions();

  const [timeVal, setTimeVal] = useState<any>(null);
  // const [isLoan, setIsLoan] = useState(false);

  const [showPurposeSelector, setShowPurposeSelector] = useState(false);
  // const [showLoanTypeSelector, setShowLoanTypeSelector] = useState(false);
  const [showReportTypeSelector, setShowReportTypeSelector] = useState(false);
  const [showFormTypeSelector, setShowFormTypeSelector] = useState(false);

  function isValidDate(date: any) {
    const parsedDate: any = new Date(date);
    // Check if the parsed date is a valid date and not NaN
    return !isNaN(parsedDate) && !isNaN(parsedDate.getDate());
  }

  // useEffect(() => {
  // const purchase = appraisalOptions.data?.appraisalPurposes.find((item) => item.id === formData.appraisal_purpose_id);
  // setIsLoan(purchase?.is_loan || false);
  // }, [appraisalOptions.data, formData.appraisal_purpose_id]);

  // useEffect(() => {
  //   if (formData.property_type_id === 1 && formData.report_type_id === 1) {
  //     console.log('TRUE');
  //     form.change('residential_form_type_ids', [10]);
  //   }
  // }, [form, formData.property_type_id, formData.report_type_id]);

  const {
    purchasePurpose,
    refinancePurpose,
    // conventionalLoanType,
    // fhaLoanType,
    // vaLoanType,
    // usdaLoanType,
    formReportType,
    narrativeFormType,
    formType1004,
    formType1004D,
    formType1004MC,
    formType1007,
    formType10073,
    formType2055,
  } = useMemo(() => {
    const purchasePurpose = appraisalOptions.data?.appraisalPurposes.find((item) => item.id === 26);
    const refinancePurpose = appraisalOptions.data?.appraisalPurposes.find((item) => item.id === 30);
    // const conventionalLoanType = appraisalOptions.data?.loanTypes.find((item) => item.id === 1);
    // const fhaLoanType = appraisalOptions.data?.loanTypes.find((item) => item.id === 4);
    // const usdaLoanType = appraisalOptions.data?.loanTypes.find((item) => item.id === 5);
    // const vaLoanType = appraisalOptions.data?.loanTypes.find((item) => item.id === 6);

    const formReportType = appraisalOptions.data?.reportTypes.find((item) => item.id === 1);
    const narrativeFormType = appraisalOptions.data?.reportTypes.find((item) => item.id === 2);
    const formType1004 = appraisalOptions.data?.residentialFormTypes.find((item) => item.id === 10);
    const formType1004D = appraisalOptions.data?.residentialFormTypes.find((item) => item.id === 12);
    const formType1004MC = appraisalOptions.data?.residentialFormTypes.find((item) => item.id === 13);
    const formType1007 = appraisalOptions.data?.residentialFormTypes.find((item) => item.id === 14);
    const formType10073 = appraisalOptions.data?.residentialFormTypes.find((item) => item.id === 17);
    const formType2055 = appraisalOptions.data?.residentialFormTypes.find((item) => item.id === 26);

    return {
      purchasePurpose,
      refinancePurpose,
      // conventionalLoanType,
      // fhaLoanType,
      // vaLoanType,
      // usdaLoanType,
      formReportType,
      narrativeFormType,
      formType1004,
      formType1004D,
      formType1004MC,
      formType1007,
      formType10073,
      formType2055,
    };
  }, [appraisalOptions.data]);

  const { appraisal_purpose_id, report_type_id, property_type_id, residential_form_type_ids, appraisal_status_id } =
    formData;

  const handleTimeChange = (time: MaterialUiPickersDate) => {
    if (!time) return;
    if (time instanceof Date && isNaN(time.getTime())) return;

    const tVal = new Date(time as Date);
    const dVal = new Date(dateVal as Date);
    dVal.setHours(tVal.getHours());
    dVal.setMinutes(tVal.getMinutes());
    dVal.setSeconds(0);

    if (isValidDate(dVal)) {
      setDateVal(dVal);
      setTimeVal(time);
    }
  };

  return (
    <>
      <Typography classes={{ root: classes.sectionHeading }}>ENGAGEMENT</Typography>
      <Divider classes={{ root: classes.divider }}></Divider>
      <Grid container direction="column" alignItems="center">
        <Grid container md={5} sm={12} xs={12}>
          <AddExistingClientButton
            label="Ordered By (Client)"
            persist={true}
            onSave={(data: any) => {
              setClientId(data?.client_id);
              setContactIds([data?.id]);
            }}
          />
        </Grid>
        <Grid container md={5} sm={12} xs={12}>
          <TextInput label="Client File #" source="client_file_number" fullWidth variant="outlined" />
        </Grid>

        <Grid container md={5} sm={12} xs={12}>
          {showPurposeSelector ? (
            <AutocompleteInput
              label="Appraisal Purpose"
              fullWidth
              source="appraisal_purpose_id"
              optionText="purpose"
              variant="outlined"
              choices={appraisalOptions.data?.appraisalPurposes ?? []}
            // onChange={(data: any) => {
            //   setIsLoan(data.isLoan);
            // }}
            />
          ) : (
            <Box>
              <Typography variant="subtitle1">Appraisal Purpose</Typography>
              <Box mb={2.5} mt={0.5}>
                <Button
                  label={purchasePurpose?.purpose || ''}
                  variant={appraisal_purpose_id === purchasePurpose?.id ? 'contained' : 'outlined'}
                  className={classes.chipButton}
                  onClick={() => {
                    setEngagementData((prev: any) => ({ ...prev, appraisal_purpose_id: purchasePurpose?.id }));
                    // form.change('appraisal_purpose_id', purchasePurpose?.id);
                    // setIsLoan(purchasePurpose?.is_loan || false);
                  }}
                />
                <Button
                  label={refinancePurpose?.purpose || ''}
                  variant={appraisal_purpose_id === refinancePurpose?.id ? 'contained' : 'outlined'}
                  className={classes.chipButton}
                  onClick={() => {
                    setEngagementData((prev: any) => ({ ...prev, appraisal_purpose_id: refinancePurpose?.id }));
                    // form.change('appraisal_purpose_id', refinancePurpose?.id);
                    // setIsLoan(refinancePurpose?.is_loan || false);
                  }}
                />
                <Button
                  label={'More'}
                  variant={'outlined'}
                  className={classes.chipButton}
                  onClick={() => setShowPurposeSelector(true)}
                />
              </Box>
            </Box>
          )}
        </Grid>

        {/* {isLoan && (
          <>
            <Grid container direction="row" alignItems="center">
              <Grid item md={3} sm={12} xs={12}></Grid>
              <Grid item md={5}>
                <TextInput label="Loan #" source="client_loan_number" fullWidth variant="outlined" />
              </Grid>
              <Grid item md={4} sm={12} xs={12}></Grid>
              <Grid item md={3} sm={12} xs={12}></Grid>
              <Grid item md={5}>
                {showLoanTypeSelector ? (
                  <AutocompleteInput
                    label="Loan Type"
                    choices={appraisalOptions.data?.loanTypes ?? []}
                    source="loan_type_id"
                    optionText="type"
                    variant="outlined"
                    fullWidth
                  />
                ) : (
                  <>
                    <Typography variant="subtitle1">Loan Type</Typography>
                    <Box mb={2.5} mt={0.5}>
                      <Button
                        label={conventionalLoanType?.type || ''}
                        variant={formData.loan_type_id === conventionalLoanType?.id ? 'contained' : 'outlined'}
                        className={classes.chipButton}
                        onClick={() => {
                          form.change('loan_type_id', conventionalLoanType?.id);
                        }}
                      />
                      <Button
                        label={fhaLoanType?.type || ''}
                        variant={formData.loan_type_id === fhaLoanType?.id ? 'contained' : 'outlined'}
                        className={classes.chipButton}
                        onClick={() => {
                          form.change('loan_type_id', fhaLoanType?.id);
                        }}
                      />
                      <Button
                        label={vaLoanType?.type || ''}
                        variant={formData.loan_type_id === vaLoanType?.id ? 'contained' : 'outlined'}
                        className={classes.chipButton}
                        onClick={() => {
                          form.change('loan_type_id', vaLoanType?.id);
                        }}
                      />
                      <Button
                        label={usdaLoanType?.type || ''}
                        variant={formData.loan_type_id === usdaLoanType?.id ? 'contained' : 'outlined'}
                        className={classes.chipButton}
                        onClick={() => {
                          form.change('loan_type_id', usdaLoanType?.id);
                        }}
                      />
                      <Button
                        label={'More'}
                        variant={'outlined'}
                        className={classes.chipButton}
                        onClick={() => setShowLoanTypeSelector(true)}
                      />
                    </Box>
                  </>
                )}
              </Grid>
              {formData.loan_type_id === 4 && (
                <Grid container item md={4}>
                  <TextInput variant="outlined" fullWidth source="fha_case_number" label="FHA Case Number" />
                </Grid>
              )}
              {formData.loan_type_id === 5 && (
                <Grid container item md={4}>
                  <TextInput variant="outlined" fullWidth source="usda_case_number" label="USDA Case Number" />
                </Grid>
              )}
              {formData.loan_type_id === 6 && (
                <Grid container item md={4}>
                  <TextInput variant="outlined" fullWidth source="va_case_number" label="VA Case Number" />
                </Grid>
              )}
            </Grid>
          </>
        )} */}

        <Grid container md={5} sm={12} xs={12}>
          {showReportTypeSelector ? (
            <AutocompleteInput
              label="Report Type"
              fullWidth
              variant="outlined"
              source="report_type_id"
              optionText="type"
              choices={appraisalOptions.data?.reportTypes ?? []}
            />
          ) : (
            <Box>
              <Typography variant="subtitle1">Report Type</Typography>
              <Box mb={2.5} mt={0.5}>
                <Button
                  label={formReportType?.type || ''}
                  variant={report_type_id === formReportType?.id ? 'contained' : 'outlined'}
                  className={classes.chipButton}
                  onClick={() => {
                    setEngagementData((prev: any) => ({ ...prev, report_type_id: formReportType?.id }));
                  }}
                />
                <Button
                  label={narrativeFormType?.type || ''}
                  variant={report_type_id === narrativeFormType?.id ? 'contained' : 'outlined'}
                  className={classes.chipButton}
                  onClick={() => {
                    setEngagementData((prev: any) => ({ ...prev, report_type_id: narrativeFormType?.id }));
                  }}
                />
                <Button
                  label={'More'}
                  variant={'outlined'}
                  className={classes.chipButton}
                  onClick={() => {
                    setShowReportTypeSelector(true);
                  }}
                />
              </Box>
            </Box>
          )}
        </Grid>

        {property_type_id === 1 && report_type_id === 1 && (
          <Grid container md={5} sm={12} xs={12}>
            {showFormTypeSelector ? (
              <AutocompleteArrayInput
                label="Form Types"
                fullWidth
                variant="outlined"
                source="residential_form_type_ids"
                optionText="type"
                choices={appraisalOptions.data?.residentialFormTypes ?? []}
              />
            ) : (
              <Box>
                <Typography variant="subtitle1">Form Type</Typography>
                <Box mb={2.5} mt={0.5}>
                  <Button
                    label={formType1004?.type || ''}
                    variant={residential_form_type_ids?.includes(formType1004?.id) ? 'contained' : 'outlined'}
                    className={classes.chipButton}
                    onClick={() => {
                      const newData = pushOrRemove(residential_form_type_ids || [], formType1004?.id ?? 0);
                      setEngagementData((prev: any) => ({ ...prev, residential_form_type_ids: newData }));
                      // form.change('residential_form_type_ids', newData);
                    }}
                  />

                  <Button
                    label={formType1004D?.type || ''}
                    variant={residential_form_type_ids?.includes(formType1004D?.id) ? 'contained' : 'outlined'}
                    className={classes.chipButton}
                    onClick={() => {
                      const newData = pushOrRemove(residential_form_type_ids || [], formType1004D?.id ?? 0);
                      // form.change('residential_form_type_ids', newData);
                      setEngagementData((prev: any) => ({ ...prev, residential_form_type_ids: newData }));
                    }}
                  />

                  <Button
                    label={formType1004MC?.type || ''}
                    variant={residential_form_type_ids?.includes(formType1004MC?.id) ? 'contained' : 'outlined'}
                    className={classes.chipButton}
                    onClick={() => {
                      const newData = pushOrRemove(residential_form_type_ids || [], formType1004MC?.id ?? 0);
                      // form.change('residential_form_type_ids', newData);
                      setEngagementData((prev: any) => ({ ...prev, residential_form_type_ids: newData }));
                    }}
                  />

                  <Button
                    label={formType1007?.type || ''}
                    variant={residential_form_type_ids?.includes(formType1007?.id) ? 'contained' : 'outlined'}
                    className={classes.chipButton}
                    onClick={() => {
                      const newData = pushOrRemove(residential_form_type_ids || [], formType1007?.id ?? 0);
                      // form.change('residential_form_type_ids', newData);
                      setEngagementData((prev: any) => ({ ...prev, residential_form_type_ids: newData }));
                    }}
                  />

                  <Button
                    label={formType10073?.type || ''}
                    variant={residential_form_type_ids?.includes(formType10073?.id) ? 'contained' : 'outlined'}
                    className={classes.chipButton}
                    onClick={() => {
                      const newData = pushOrRemove(residential_form_type_ids || [], formType10073?.id ?? 0);
                      // form.change('residential_form_type_ids', newData);
                      setEngagementData((prev: any) => ({ ...prev, residential_form_type_ids: newData }));
                    }}
                  />

                  <Button
                    label={formType2055?.type || ''}
                    variant={residential_form_type_ids?.includes(formType2055?.id) ? 'contained' : 'outlined'}
                    className={classes.chipButton}
                    onClick={() => {
                      const newData = pushOrRemove(residential_form_type_ids || [], formType2055?.id ?? 0);
                      // form.change('residential_form_type_ids', newData);
                      setEngagementData((prev: any) => ({ ...prev, residential_form_type_ids: newData }));
                    }}
                  />

                  <Button
                    label={'More'}
                    variant={'outlined'}
                    className={classes.chipButton}
                    onClick={() => {
                      setShowFormTypeSelector(true);
                    }}
                  />
                </Box>
              </Box>
            )}
          </Grid>
        )}

        {/* <>
          <Grid container item md={3} sm={12} xs={12} />
          <Grid item md={5} sm={12} xs={12}>
            <Typography variant="subtitle1">Report Addressed To</Typography>
            <Box mb={2.5} mt={0.5}>
              <Button label={'Client'} variant={'contained'} className={classes.chipButton} />
              <Button label={'Owners'} variant={'outlined'} className={classes.chipButton} />
              <Button label={'More'} variant={'outlined'} className={classes.chipButton} />
            </Box>
          </Grid>
          <Grid container item md={4} sm={12} xs={12} />
        </> */}

        <Grid container md={5} sm={12} xs={12}>
          {appraisal_status_id === 8 && (
            <CurrencyInput label="Quote Fee" variant="outlined" fullWidth source="quote_fee" />
          )}
          {appraisal_status_id === 1 && (
            <CurrencyInput
              fullWidth
              variant="outlined"
              label="Report Fee"
              source="report_fee"
              onChange={(e: any) => setReportFee(e.target.value)}
            // style={{
            //   marginBottom: '7px',
            // }}
            />
          )}
        </Grid>

        <Grid container md={5} sm={12} xs={12}>
          {appraisal_status_id === 8 && <DateInput source="quote_made_date" variant="outlined" fullWidth />}

          {appraisal_status_id === 1 && <DateInput source="engagement_date" variant="outlined" fullWidth />}
        </Grid>

        {appraisal_status_id === 1 && (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container sm={12} md={5} lg={5} style={{ marginBottom: '1.2rem', marginTop: '-3px' }}>
              <Grid container sm={12} md={7} lg={7} className={classes.dateWrapper}>
                <KeyboardDatePicker
                  label={<FieldTitle label="Due Date" source="due_date" />}
                  autoOk
                  variant="dialog"
                  inputVariant="outlined"
                  value={dateVal}
                  format="MM/dd/yyyy"
                  onChange={function (date: MaterialUiPickersDate): void {
                    setDateVal(date);
                    //for setting 5:00 PM as default time
                    if (date && !timeVal) {
                      date.setHours(17); // Set custom hours
                      date.setMinutes(0); // Set custom minutes
                      date.setSeconds(0);
                      setTimeVal(date.toLocaleString('en-US'));
                    }
                  }}
                  margin="dense"
                  clearable
                  fullWidth
                />
              </Grid>

              <Grid container sm={12} md={5} lg={5} alignItems="flex-end" className={classes.timeWrapper}>
                <KeyboardTimePicker
                  label={<FieldTitle label="Time" source="d_time" />}
                  variant="dialog"
                  inputVariant="outlined"
                  autoOk
                  value={timeVal}
                  onChange={handleTimeChange}
                  margin="dense"
                  clearable
                  keyboardIcon={<AccessTime />}
                  fullWidth
                  disabled={!timeVal}
                />
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        )}
      </Grid>
    </>
  );
};

export default EngagementSection;
