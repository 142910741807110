import { Box, Card, CardHeader, Divider } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import React, { useEffect, useState } from 'react';
import { Button, FormWithRedirect, SaveButton, useNotify, useRefresh, useUpdate } from 'react-admin';
import AddContactField from 'views/Contact/components/AddContactField';
import styles from '../hooks/useContactPopupStyles';
import AddContact from './AddContactDialog';

type AddContactProps = {
  persist: boolean;
  onSave?: (value: any) => any;
  clientId?: string;
  clientTypeId?: number;
  appraisal?: any;
};

function AddExistingContactButton({ clientTypeId, clientId, onSave, persist, appraisal }: AddContactProps) {
  const popUpClasses = styles();
  const [showDialog, setShowDialog] = useState(false);
  const [isNewContact, setIsNewContact] = useState(false);
  const [contactName, setContactName] = useState('');
  const refresh = useRefresh();
  const notify = useNotify();
  const [update] = useUpdate('appraisal', appraisal?.id);
  // const [searchIds, setSearchIds] = useState([]);
  const [filters, setFilters] = useState({});
  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
    refresh();
  };

  const initialFilterValues = {
    id: {
      format: 'raw-query',
      value: {
        _nin: appraisal?.contact_ids || [],
      },
    },
    client_id: appraisal?.client_id,
  };

  useEffect(() => {
    setFilters(initialFilterValues);
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async (values: any) => {
    const cid = values.selected_contact_id.split('.');
    if (cid.length === 2) {
      // Create new contact
      setContactName(cid[0]);
      setIsNewContact(true);
    } else {
      // Add existing contact
      await updateAppraisal(values.selected_contact_id);
      setShowDialog(false);
      refresh();
      notify('contact.added');
    }
  };

  const updateAppraisal = (contactId: any) => {
    return new Promise((resolve: any) => {
      if (appraisal) {
        update(
          {
            payload: {
              id: appraisal?.id,
              data: {
                contact_ids: appraisal.contact_ids ? appraisal.contact_ids.concat([contactId]) : [contactId],
              },
            },
          },
          {
            onSuccess: () => {
              resolve();
            },
          },
        );
      } else {
        resolve();
      }
    });
  };

  const handleTextSearch = (ids: any) => {
    // setSearchIds(ids);
    if (ids) {
      const filter: any = {
        'id,id': {
          format: 'raw-query',
          value: {
            _nin: appraisal?.contact_ids || [],
            _in: ids || [],
          },
        },
      };
      delete filter['client_id'];
      setFilters(filter);
    } else {
      setFilters(initialFilterValues);
    }
  };

  return (
    <>
      <Button onClick={handleClick} label="ADD CONTACT">
        <IconContentAdd />
      </Button>
      <Dialog fullWidth={true} maxWidth={'sm'} open={showDialog} onClose={handleCloseClick} aria-label="Add Contact">
        <FormWithRedirect
          initialValues={{
            client_id: clientId,
          }}
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, form }: any) => (
            <DialogContent classes={{ root: popUpClasses.dialogContent }}>
              <Card variant="outlined" className={popUpClasses.formBottom}>
                <CardHeader title="Add Contact" classes={{ root: popUpClasses.cardHeader }}></CardHeader>
                <Divider></Divider>
                <Box className={popUpClasses.formContainer}>
                  <AddContactField
                    // filter={{
                    // 'id,id': {
                    //   format: 'raw-query',
                    //   value: {
                    //     _nin: appraisal?.contact_ids || [],
                    //     _in: searchIds || [],
                    //   },
                    // },
                    //   client_id: searchIds ? '' : appraisal?.client_id,
                    // }}
                    filter={filters}
                    addLabel={false}
                    source="contact_ids"
                    fullWidth
                    onSubmit={handleSubmitWithRedirect}
                    onTextSearch={handleTextSearch}
                    clearTextSearch={() => setFilters(initialFilterValues)}
                  />
                  <Box style={{ display: 'flex', justifyContent: 'end' }}>
                    <AddContact
                      contactName={contactName}
                      showDialogView={isNewContact}
                      clientTypeId={clientTypeId}
                      clientId={clientId}
                      persist={true}
                      appraisal={appraisal}
                      onSave={handleCloseClick}
                      label="ADD NEW CONTACT"
                      active={!form.getState().values?.selected_contact_id}
                      contactsCount={appraisal?.contact_ids?.length}
                      onCancel={() => form.reset()}
                    ></AddContact>
                  </Box>
                  <DialogActions classes={{ root: popUpClasses.editActions }} style={{ padding: '0px' }}>
                    <Button label="ra.action.cancel" onClick={handleCloseClick}>
                      <IconCancel />
                    </Button>
                    <SaveButton
                      handleSubmitWithRedirect={handleSubmitWithRedirect}
                      disabled={!form.getState().values?.selected_contact_id}
                    />
                  </DialogActions>
                </Box>
              </Card>
            </DialogContent>
          )}
        />
      </Dialog>
    </>
  );
}
export default AddExistingContactButton;
