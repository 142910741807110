import { Divider, Tab, Tabs } from '@material-ui/core';
import Empty from 'ra-ui-materialui/lib/list/Empty';
import * as React from 'react';
import { BulkActionProps, List, ListProps, useGetIdentity, useListContext } from 'react-admin';
import ListActions from 'shared/components/Resource/ListActions';
import { useListStyles } from 'shared/components/Resource/styles';
import { INDEX_LIST_PER_PAGE } from 'shared/constants/config';
import { standardMutationRoles } from 'shared/constants/roles';
import { useClientsTotal } from 'shared/hooks/useClientOptions';
import useLocationQuery from 'shared/hooks/useLocationQuery';
import useMaxDimensions from 'shared/hooks/useMaxDimension';
import usePersistentQueryString from 'shared/hooks/usePersistentQueryString';
import { createExporter } from 'shared/utils';
import { exportFields } from './fields';
import GridComponent from './Grid';
import ListFilter from './ListFilter';

const ClientList = (props: ListProps): JSX.Element => {
  const classes = useListStyles();
  usePersistentQueryString({});
  const { identity } = useGetIdentity();
  const exporter = React.useMemo(() => createExporter(exportFields, 'clients'), []);

  if (!identity) return <span />;
  const { role } = identity;
  return (
    <List
      {...props}
      exporter={exporter}
      perPage={INDEX_LIST_PER_PAGE}
      classes={classes}
      sort={{ field: 'name', order: 'ASC' }}
      filters={<ListFilter />}
      bulkActionButtons={false}
      actions={<ListActions hasCreate={standardMutationRoles.includes(role ?? '')} />}
      resource="clients"
      syncWithLocation
      hasCreate={false}
    >
      <ClientGrid role={role} {...props} />
    </List>
  );
};

export default ClientList;

type TabbedDatagridProps = ListProps &
  BulkActionProps & {
    role: string;
  };

function ClientGrid(props: TabbedDatagridProps) {
  const { role, filterValues } = props;
  const [, onChangeQuery] = useLocationQuery();
  const dimensions = useMaxDimensions();
  const listContext = useListContext();

  const total = useClientsTotal();
  const { data }: any = total[0];
  const [tabs, setTabs] = React.useState([]);

  const alphabets = React.useMemo(() => {
    const alpha = Array.from(Array(26)).map((e, i) => i + 65);
    const alphabet: any = ['All', ...alpha.map((x) => String.fromCharCode(x))];
    return alphabet;
  }, []);

  React.useEffect(() => {
    if (alphabets?.length !== 0) {
      const arr: any = ['All'];
      alphabets?.map((each: String) => {
        if (data) {
          Object.entries(data)?.map((item: any) => {
            if (each === item[0] && item[1]?.aggregate?.count !== 0) {
              arr.push(each);
            }
          });
        }
      });
      setTabs(arr);
    }
  }, [alphabets, data]);

  const handleChange = (event: any, newValue: any) => {
    onChangeQuery({
      filter: JSON.stringify({
        ...filterValues,
        name:
          newValue === 'All'
            ? ''
            : {
              format: 'raw-query',
              value: {
                _similar: `(${newValue})%`,
              },
            },
      }),
    });
  };

  return (
    <div style={{ width: dimensions.width }}>
      <Tabs
        variant="scrollable"
        textColor="primary"
        indicatorColor="primary"
        value={filterValues?.name?.value?._similar?.charAt(1) || 'All'}
        onChange={handleChange}
        aria-label="scrollable auto tabs example"
        scrollButtons="auto"
      >
        {tabs?.length !== 0 &&
          tabs?.map((each: String, index: any) => (
            <Tab label={each} key={index} value={each} style={{ width: '50px', maxWidth: '50px', minWidth: '50px' }} />
          ))}
      </Tabs>
      <Divider />
      {listContext.total === 0 && <Empty resource="clients" />}
      {listContext.total !== 0 ? <GridComponent role={role} /> : ''}
    </div>
  );
}
