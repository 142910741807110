import { Box, Divider, Tab, Tabs } from '@material-ui/core';
import { omit } from 'lodash';
import * as React from 'react';
import { Datagrid, DateField, FunctionField, TextField, useGetIdentity } from 'react-admin';
import { withGridLoader } from 'shared/components/TablePreloader';
import { rolesMapping } from 'shared/constants/roles';
import useLocationQuery from 'shared/hooks/useLocationQuery';
import FileNumber from 'views/Appraisal/components/FileNumber';
import ReportFeeColumn from 'views/Appraisal/components/ReportFeeColumn';
// import { gql, useQuery } from '@apollo/client';
// import { Expenses_Aggregate } from 'shared/generated/types';
// import { useLocation } from 'react-router-dom';
// import { parse } from 'query-string';
// import { buildVariables } from 'ra-data-hasura';
// import instrospectionResult from 'shared/dataProvider/instrospection';
// import { useIsLimitedAndRestrictedRole } from 'shared/hooks/useResourcePermissions';
// import { formatCurrency } from 'shared/utils';

// const EXPENSES_STATUS_QUERY = gql`
//   query ExpenseStatusAggregates($where: expenses_bool_exp) {
//     expenses_aggregate(where: $where) {
//       aggregate {
//         count
//         sum {
//           total_amount
//         }
//       }
//     }
//   }
// `;
// type ExpensesStatusAggregatesQueryResponse = {
//   expenses_aggregate: Expenses_Aggregate;
// };

const ExpenseGrid = (props: any) => {
  const [, onChangeQuery] = useLocationQuery();
  const { filterValues } = props;

  // const location = useLocation();
  // const qs = parse(location.search);
  // const expenseResource = instrospectionResult.resources.find((e: any) => e.type.name === 'fees');

  // const getAggregates = (expenseType: any) => {
  //   const variables = buildVariables(null)(expenseResource, 'GET_LIST', {
  //     filter: {
  //       ...JSON.parse(qs.filter || '{}'),
  //       expense_type: expenseType,
  //     },
  //   });
  //   return pick(variables, 'where');
  // };

  // const { data } = useQuery<ExpensesStatusAggregatesQueryResponse>(EXPENSES_STATUS_QUERY, {
  //   fetchPolicy: 'network-only',
  // });
  // const { data: appraisalExpensesData } = useQuery<ExpensesStatusAggregatesQueryResponse>(EXPENSES_STATUS_QUERY, {
  //   fetchPolicy: 'network-only',
  //   variables: getAggregates({
  //     format: 'raw-query',
  //     value: {
  //       _eq: 'Appraisal',
  //     },
  //   }),
  // });
  // const { data: otherExpensesData } = useQuery<ExpensesStatusAggregatesQueryResponse>(EXPENSES_STATUS_QUERY, {
  //   fetchPolicy: 'network-only',
  //   variables: getAggregates({
  //     format: 'raw-query',
  //     value: {
  //       _eq: 'Standard',
  //     },
  //   }),
  // });

  const handleChange = (event: React.ChangeEvent<{}>, value: any) => {
    if (value === 'Appraisal') {
      onChangeQuery({
        filter: JSON.stringify({
          ...filterValues,
          expense_type: 'Appraisal',
        }),
      });
    } else if (value === 'Standard') {
      onChangeQuery({
        filter: JSON.stringify({
          ...filterValues,
          expense_type: 'Standard',
        }),
      });
    } else {
      onChangeQuery({
        filter: JSON.stringify(omit(filterValues, 'expense_type')),
      });
    }
  };

  // const canViewAggregate = !useIsLimitedAndRestrictedRole();

  const TabLabel = ({ title, data }: any) => (
    <>
      <span>{title}</span>{' '}
      {/* {canViewAggregate && (
        <span style={{ fontSize: '0.75rem', fontWeight: 400 }}>
          {data?.expenses_aggregate?.aggregate?.sum?.total_amount
            ? formatCurrency(data?.expenses_aggregate?.aggregate?.sum?.total_amount)
            : ''}
        </span>
      )} */}
    </>
  );
  const TABS = [
    {
      title: (
        <TabLabel
          title="All Expenses"
        // data={data}
        />
      ),
      index: 0,
      key: 'All Expenses',
    },
    {
      title: (
        <TabLabel
          title="Appraisal Expenses"
        //  data={appraisalExpensesData}
        />
      ),
      index: 1,
      key: 'Appraisal',
    },
    {
      title: (
        <TabLabel
          title="Other Expenses"
        //  data={otherExpensesData}
        />
      ),
      index: 2,
      key: 'Standard',
    },
  ];

  return (
    <Box>
      <Tabs
        variant="scrollable"
        value={filterValues?.expense_type || 'All Expenses'}
        textColor="primary"
        indicatorColor="primary"
        onChange={handleChange}
      >
        {TABS.map((choice) => (
          <Tab key={choice?.index} label={choice?.title} value={choice?.key} />
        ))}
      </Tabs>

      <Divider />
      <ExpenseDataGrid />
    </Box>
  );
};
function ExpenseDataGrid() {
  const { identity } = useGetIdentity();

  const restrictedMutation = identity?.role === rolesMapping[4];

  return (
    <Datagrid
      optimized
      rowClick={(id, resource, record) => {
        if (restrictedMutation) {
          return '';
        }
        return record?.expense_type === 'Appraisal' ? `/appraisals/${record?.appraisal_id}#accounting` : 'edit';
      }}
    >
      <FileNumber label="File #" source="appraisal_file_number" />
      <TextField label="Description" source="description" />
      <FunctionField
        label="Qty./Perc."
        sortBy="quantity"
        sortByOrder="ASC"
        render={(record: any) =>
          `${record.rate_type_id === 1 ? `${(record.rate * 100).toFixed(0)}%` : record.quantity}`
        }
      />
      <FunctionField
        label="Rate"
        sortBy="rate"
        sortByOrder="ASC"
        render={(record: any) => `${record.rate_type_id === 1 ? '' : '$' + record.rate}`}
      />
      <ReportFeeColumn label="Total" source="total_amount" />
      <DateField label="Date" source="expense_date" />
      <DateField label="Created" source="created_at" />
    </Datagrid>
  );
}

export default withGridLoader()(ExpenseGrid);
