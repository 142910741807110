import * as React from 'react';
import { List, ListProps, useGetIdentity } from 'react-admin';
import ListActions from 'shared/components/Resource/ListActions';
import { useListStyles } from 'shared/components/Resource/styles';
import { INDEX_LIST_PER_PAGE } from 'shared/constants/config';
import { createExporter } from 'shared/utils';
import { exportFields } from './fields';
import ListFilter from './Filter';
import Grid from './Grid';

const FeesList = (props: ListProps): JSX.Element => {
  const classes = useListStyles();
  const { identity } = useGetIdentity();
  const exporter = React.useMemo(() => createExporter(exportFields, 'fees'), []);
  if (!identity) return <span />;

  return (
    <List
      {...props}
      exporter={exporter}
      basePath="/fees"
      perPage={INDEX_LIST_PER_PAGE}
      classes={classes}
      sort={{ field: 'created_at', order: 'DESC' }}
      filters={<ListFilter />}
      bulkActionButtons={false}
      actions={<ListActions hasCreate={false} />}
      resource="fees"
      syncWithLocation
      hasShow={false}
      // hasCreate={false}
      // hasEdit={true}
      hasList={true}
    >
      <Grid props={props} />
    </List>
  );
};

export default FeesList;
