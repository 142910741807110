import { Box, Divider, Tab, Tabs } from '@material-ui/core';
import { omit } from 'lodash';
import React from 'react';
import { Datagrid, DateField, FunctionField, TextField } from 'react-admin';
import { withGridLoader } from 'shared/components/TablePreloader';
import useLocationQuery from 'shared/hooks/useLocationQuery';
import FileNumber from 'views/Appraisal/components/FileNumber';
import ReportFeeColumn from 'views/Appraisal/components/ReportFeeColumn';
// import { Fees_Aggregate } from 'shared/generated/types';
// import { gql, useQuery } from '@apollo/client';
// import { formatCurrency } from 'shared/utils';
// import instrospectionResult from 'shared/dataProvider/instrospection';
// import { useLocation } from 'react-router-dom';
// import { parse } from 'query-string';
// import { buildVariables } from 'ra-data-hasura';
// import { useIsLimitedAndRestrictedRole } from 'shared/hooks/useResourcePermissions';

// const FEE_STATUS_QUERY = gql`
//   query FeeStatusAggregates($where: fees_bool_exp) {
//     fees_aggregate(where: $where) {
//       aggregate {
//         count
//         sum {
//           total
//         }
//       }
//     }
//   }
// `;
// type FeeStatusAggregatesQueryResponse = {
//   fees_aggregate: Fees_Aggregate;
// };

const FeesGrid = (props: any) => {
  const [, onChangeQuery] = useLocationQuery();
  const { filterValues } = props;
  // const location = useLocation();
  // const qs = parse(location.search);
  // const feesResource = instrospectionResult.resources.find((e: any) => e.type.name === 'fees');

  // const getAggregates = (description: any) => {
  //   const variables = buildVariables(null)(feesResource, 'GET_LIST', {
  //     filter: {
  //       ...JSON.parse(qs.filter || '{}'),
  //       description: description,
  //     },
  //   });
  //   return pick(variables, 'where');
  // };

  // const { data } = useQuery<FeeStatusAggregatesQueryResponse>(FEE_STATUS_QUERY, {
  //   fetchPolicy: 'network-only',
  // });
  // const { data: reportData } = useQuery<FeeStatusAggregatesQueryResponse>(FEE_STATUS_QUERY, {
  //   fetchPolicy: 'network-only',
  //   variables: getAggregates({
  //     format: 'raw-query',
  //     value: {
  //       _eq: 'Report Fee',
  //     },
  //   }),
  // });
  // const { data: otherData } = useQuery<FeeStatusAggregatesQueryResponse>(FEE_STATUS_QUERY, {
  //   fetchPolicy: 'network-only',
  //   variables: getAggregates({
  //     format: 'raw-query',
  //     value: {
  //       _neq: 'Report Fee',
  //     },
  //   }),
  // });

  const handleChange = (event: React.ChangeEvent<{}>, value: any) => {
    if (value === 0) {
      onChangeQuery({
        filter: JSON.stringify(omit(filterValues, 'description')),
      });
    } else if (value === 1) {
      onChangeQuery({
        filter: JSON.stringify({
          ...filterValues,
          description: {
            format: 'raw-query',
            value: {
              _eq: 'Report Fee',
            },
            index: value,
          },
        }),
      });
    } else if (value === 2) {
      onChangeQuery({
        filter: JSON.stringify({
          ...filterValues,
          description: {
            format: 'raw-query',
            value: {
              _neq: 'Report Fee',
            },
            index: value,
          },
        }),
      });
    }
  };
  // const canViewAggregate = !useIsLimitedAndRestrictedRole();

  const TabLabel = ({ title, data }: any) => (
    <>
      <span>{title}</span>{' '}
      {/* {canViewAggregate && (
        <span style={{ fontSize: '0.75rem', fontWeight: 400 }}>
          {data?.fees_aggregate?.aggregate?.sum?.total
            ? formatCurrency(data?.fees_aggregate?.aggregate?.sum?.total)
            : ''}
        </span>
      )} */}
    </>
  );
  const TABS = [
    {
      title: (
        <TabLabel
          title="All Fees"
          // data={data}
        />
      ),
      index: 0,
      key: 'all_fees',
    },
    {
      title: (
        <TabLabel
          title="Report Fees"
          //  data={reportData}
        />
      ),
      index: 1,
      key: 'report_fees',
    },
    {
      title: (
        <TabLabel
          title="Other Fees"
          //  data={otherData}
        />
      ),
      index: 2,
      key: 'other_fees',
    },
  ];

  return (
    <Box>
      <Tabs
        variant="scrollable"
        value={filterValues?.description?.index || 0}
        textColor="primary"
        indicatorColor="primary"
        onChange={handleChange}
      >
        {TABS.map((choice) => (
          <Tab key={choice?.index} label={choice?.title} style={{ width: '62px' }} value={choice?.index} />
        ))}
      </Tabs>

      <Divider />
      <FeeDataGrid />
    </Box>
  );
};

export default withGridLoader()(FeesGrid);

function FeeDataGrid() {
  return (
    <Datagrid optimized rowClick={(id, resource, record) => `/appraisals/${record?.appraisal_id}#accounting`}>
      <FileNumber label="File #" source="appraisal_file_number" />
      <TextField label="Description" source="description" />
      <FunctionField
        label="Qty./Perc."
        sortBy="quantity"
        sortByOrder="ASC"
        render={(record: any) =>
          `${record.rate_type_id === 1 ? `${(record.rate * 100).toFixed(0)}%` : record.quantity}`
        }
      />
      <FunctionField
        label="Rate"
        sortBy="rate"
        sortByOrder="ASC"
        render={(record: any) => `${record.rate_type_id === 1 ? '' : '$' + record.rate}`}
      />
      <ReportFeeColumn label="Total" source="total" />
      <DateField label="Completed" source="completed_date" />
      <DateField label="Paid" source="paid_date" />
      <DateField label="Created" source="created_at" />
    </Datagrid>
  );
}
