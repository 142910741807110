import React, { useEffect, useState } from 'react';
import AddClientField from './AddClientFieldV2';

type AddContactProps = {
  persist: boolean;
  onSave: (value: any) => any;
  clientId?: string;
  clientTypeId?: number;
  appraisal?: any;
  label?: string;
};

function AddExistingClientButton({ onSave, label }: AddContactProps) {
  const [filters, setFilters] = useState({});

  useEffect(() => {
    setFilters({});
    // eslint-disable-next-line
  }, []);

  const handleTextSearch = (ids: any) => {
    // setSearchIds(ids);
    if (ids) {
      const filter: any = {
        'id,id': {
          format: 'raw-query',
          value: {
            _in: ids || [],
          },
        },
        client_id: {
          format: 'raw-query',
          value: {
            _is_null: false,
          },
        },
      };
      setFilters(filter);
    } else {
      setFilters({});
    }
  };

  return (
    <AddClientField
      filter={filters}
      addLabel={false}
      source="client_id"
      fullWidth
      onTextSearch={handleTextSearch}
      clearTextSearch={() => setFilters({})}
      onAdd={(data: any) => {
        onSave(data);
      }}
      label={label}
    />
  );
}
export default AddExistingClientButton;
