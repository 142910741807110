import { Box, Grid, Modal, Typography, useMediaQuery } from '@material-ui/core';
import IconCancel from '@material-ui/icons/Cancel';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, SaveButton, SimpleForm, useDataProvider } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';

import CustomDateTimeInput from 'shared/components/CustomDateTimeInput';
import EditAction from 'shared/components/Resource/EditAction';
import { useAppraisalCommission } from 'shared/hooks/useAppraisalFees';
import useFormPermissions from 'shared/hooks/useResourcePermissions';
import { displayFormattedDate, formatAddress } from 'shared/utils';
import { Appraisal } from 'views/Appraisal/types';
import { AppraisalTabs } from './AppraisalTabs';
import AppraisalPdf from './components/AppraisalPdf';
import {
  EngagementSection,
  InspectionSection,
  InvoiceSection,
  PropertySection,
  RequestSection,
  WorkFlowSection,
} from './components/Edit';
import { activeDateFieldType } from './components/Edit/types';
import ExportButton from './components/ExportButton';
import getAppraisalPermission from './permission';

import { simpleFormContainer, styleLeft, styleRight } from 'shared/hooks/useEditFormStyle';
import styles from './hooks/useAssigneeListStyles';
// import PdfTab from './components/PdfTab';

type Props = {
  record?: any;
  setLoading?: any;
  commissionData?: any;
  refetch?: any;
};

function AppraisalEditForm(props: Props) {
  if (props.record && props.record.property && props.record.property.location_address) {
    props.record.property.location_address = formatAddress(props?.record?.property?.location_address ?? '');
  }

  return (
    <SimpleForm {...props} toolbar={<span></span>}>
      <AppraisalEditFormContainer {...props}></AppraisalEditFormContainer>
      {/* <PdfTab /> */}
    </SimpleForm>
  );
}

function AppraisalEditFormContainer(props: Props) {
  const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
  const classes = simpleFormContainer();
  const formData = useFormState();
  const [{ permissions }] = useFormPermissions({ getPermission: getAppraisalPermission });
  function getAppraisalTitle(record: Appraisal) {
    return [record.appraisal_file_number, record.property.location_address].filter(Boolean).join(' - ');
  }

  const [getAppraisalCommission, commissionData] = useAppraisalCommission({
    appraisalId: formData?.values?.id,
  });

  useEffect(() => {
    getAppraisalCommission();
  }, [getAppraisalCommission]);

  return (
    <Box className={classes.formContainer}>
      <EditAction
        basePath="/appraisals"
        resource="appraisal"
        record={{
          id: formData.values.id,
          name: `${formData.values.appraisal_file_number}-${formData.values.property.location_address}`,
        }}
        permissions={permissions}
        resourceCount={0}
        export={
          <ExportButton
            record={formData.values as any}
            pdfTitleGetter={(record: Appraisal) =>
              `${getAppraisalTitle(record)}-${displayFormattedDate(new Date().toISOString(), 'MM-dd-yyyy')}`
                .replace(/\W+/g, '-')
                .toLowerCase() + '.pdf'
            }
            pdfRenderer={(props) => <AppraisalPdf {...props} />}
            getPermission={getAppraisalPermission}
          ></ExportButton>
        }
      />
      <Grid container direction={isSmallScreen ? 'column-reverse' : 'row'} alignItems="flex-start" spacing={4}>
        <Grid container item lg={8} md={7} xs={12} sm={12}>
          <AppraisalFieldsLeft commissionData={commissionData}></AppraisalFieldsLeft>
        </Grid>
        <Grid container item lg={4} md={5} xs={12} sm={12}>
          <AppraisalFieldsRight
            commissionData={commissionData}
          // refetch={() => commissionData.refetch()}
          ></AppraisalFieldsRight>
        </Grid>
      </Grid>
    </Box>
  );
}

function AppraisalFieldsLeft(props: Props) {
  const classes = styleLeft();

  return (
    <Box className={classes.formContainer}>
      <PropertySection />

      <AppraisalTabs commissionData={props?.commissionData} />
    </Box>
  );
}

function AppraisalFieldsRight(props: Props) {
  const classes = styleRight();
  const modalClasses = styles();
  const dataProvider = useDataProvider();

  const formData = useFormState();
  const form = useForm();

  const [workflowEdit, setWorkflowEdit] = useState(false);
  const [inspectionEdit, setInspectionEdit] = useState(false);

  const [openDateModal, setOpenDateModal] = useState<boolean>(false);
  const [activeDateField, setActiveDateField] = useState<activeDateFieldType>();
  const [meetingContacts, setMeetingContacts] = useState<any>([]);

  const [orderedContactsView, setOrderedContactsView] = useState<any>([]);

  const [borrowerContactsView, setBorrowerContactsView] = useState<any>([]);

  const [addressedContactsView, setAddressedContactsView] = useState<any>([]);

  const getContactInformation = (id: any, type: string) => {
    let contacts: any = [];
    dataProvider.getMany('contacts', { ids: id }).then(({ data }) => {
      contacts = data.map((item: any) => ({
        id: item?.id,
        full_name: item?.full_name,
        first_name: item?.first_name,
        last_name: item?.last_name,
        email: item?.email,
        phone: item?.phone_number,
        appraisalsCount: item?.appraisals_count,
        contact_type_id: item?.contact_type_id,
        type: item?.type,
      }));
      if (type === 'meeting') {
        setMeetingContacts(contacts);
      } else if (type === 'ordered') {
        setOrderedContactsView(contacts);
      } else if (type === 'borrower') {
        setBorrowerContactsView(contacts);
      } else {
        setAddressedContactsView(contacts);
      }
    });
  };
  useMemo(() => {
    if (formData?.values?.inspection_contact_ids?.length > 0) {
      getContactInformation(formData?.values?.inspection_contact_ids, 'meeting');
    } else setMeetingContacts([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, formData?.values?.inspection_contact_ids]);

  useEffect(() => {
    if (formData?.values?.ordered_by_contact_ids?.length > 0) {
      getContactInformation(formData?.values?.ordered_by_contact_ids, 'ordered');
    } else setOrderedContactsView([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData?.values?.ordered_by_contact_ids, form]);

  useEffect(() => {
    if (formData?.values?.borrower_contact_ids?.length > 0) {
      getContactInformation(formData?.values?.borrower_contact_ids, 'borrower');
    } else setOrderedContactsView([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData?.values?.borrower_contact_ids, form]);

  useEffect(() => {
    if (formData?.values?.addressed_to_contact_ids?.length > 0) {
      getContactInformation(formData?.values?.addressed_to_contact_ids, 'addressed');
    } else setAddressedContactsView([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData?.values?.addressed_to_contact_ids, form]);

  const save = useCallback(debounce(form.submit, 0), []);

  return (
    <>
      <Modal open={openDateModal} onClose={() => setOpenDateModal(!openDateModal)}>
        <Box className={modalClasses.paper}>
          <Typography classes={{ root: modalClasses.heading }}>Change {activeDateField?.label || 'Date'}</Typography>
          <CustomDateTimeInput
            source={activeDateField?.key}
            initialValue={activeDateField ? formData?.values[activeDateField.key] : null}
            onChange={(val: any) => {
              activeDateField && form.change(activeDateField?.key, val?.toISOString());
            }}
          />
          <Box display={'flex'} justifyContent={'space-between'}>
            <Button
              onClick={() => {
                setOpenDateModal(false);
                if (activeDateField) {
                  form.change(activeDateField.key, formData.initialValues[activeDateField.key]);
                  activeDateField?.key === 'due_date' ? setWorkflowEdit(false) : setInspectionEdit(false);
                }
              }}
              label="Cancel"
              startIcon={<IconCancel />}
            />
            <SaveButton
              handleSubmitWithRedirect={() => {
                save();
                activeDateField && activeDateField?.key === 'due_date'
                  ? setWorkflowEdit(false)
                  : setInspectionEdit(false);
                setOpenDateModal(false);
              }}
            />
          </Box>
        </Box>
      </Modal>

      <Box className={classes.formContainer}>
        <WorkFlowSection
          commissionData={props.commissionData}
          setActiveDateField={setActiveDateField}
          setOpenDateModal={setOpenDateModal}
        />

        <InspectionSection
          setActiveDateField={setActiveDateField}
          workflowEdit={workflowEdit}
          setOpenDateModal={setOpenDateModal}
          inspectionEdit={inspectionEdit}
          setInspectionEdit={setInspectionEdit}
          meetingContacts={meetingContacts}
          setMeetingContacts={setMeetingContacts}
        />

        <RequestSection />

        <EngagementSection
          addressedContactsView={addressedContactsView}
          borrowerContactsView={borrowerContactsView}
          orderedContactsView={orderedContactsView}
        />

        <InvoiceSection />
      </Box>
    </>
  );
}

export default AppraisalEditForm;
