import { Grid, Typography } from '@material-ui/core';
import React, { memo } from 'react';
import styles from '../hooks/useContactPopupStyles';

const VerticalLayoutGrid = memo(({ title, applyMargin = false, children }: any) => {
  const popUpClasses = styles();
  return (
    <Grid
      container
      direction="row"
      md={7}
      sm={12}
      justify="space-between"
      style={!applyMargin ? { marginBottom: '-7px' } : {}}
      className={popUpClasses.commissionGrid}
    >
      <Typography className={popUpClasses.labelMargin}>{title}</Typography>
      <Grid item md={3} sm={5} xs={7} alignItems="center">
        {children}
      </Grid>
    </Grid>
  );
});

export default VerticalLayoutGrid;
