import React from 'react';
import { Datagrid, Record, TextField } from 'react-admin';
import AddressField from 'shared/components/AddressField';
import HighlightTextField from 'shared/components/HighlightTextField/HighlightTextField';
import ResourceCount from 'shared/components/ResourceCount';
import DateInColumn from './components/DateInColumn';

const GridComponent = () => {
  return (
    <Datagrid rowClick="edit" optimized>
      <HighlightTextField label="Name" source="full_name" />
      <HighlightTextField source="email" />
      <TextField label="Phone" source="phone_number" />
      <AddressField source="location_address" label="Location" />
      <DateInColumn label="Last Active" source="last_active_at" align="left" />
      <ResourceCount
        label="Appraisals"
        basePath="/appraisals"
        filter={(record: Record) => ({
          assignee_user_account_ids: [record.user_account_id],
        })}
        countKey="appraisals_count"
        source="appraisals_count"
      />
    </Datagrid>
  );
};

export default GridComponent;
