import Typography from '@material-ui/core/Typography';
import format from 'date-fns/format';
import React from 'react';
import { AppraisalEvent } from 'views/Appraisal/types';

type AgendaTimeProps = {
  event: AppraisalEvent;
};

function AgendaTime(props: any) {
  const { type, due_date, inspection_date, schedule_type } = props.event;

  let event = '';

  if (schedule_type === 'task') {
    event = `Task Due @ ${format(new Date(due_date), 'hh:mm a')}`;
  } else if (type === 'due_date') {
    event = `Appraisal Due @ ${format(new Date(due_date), 'hh:mm a')}`;
  } else if (type === 'inspection_date') {
    event = `Appraisal Inspection @ ${format(new Date(inspection_date), 'hh:mm a')}`;
  }
  return <Typography variant="body2">{event}</Typography>;
}
export default AgendaTime;
