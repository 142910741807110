import { makeStyles } from '@material-ui/core';
import React from 'react';
import { FieldProps } from 'react-admin';
import useLocationQuery from 'shared/hooks/useLocationQuery';
import { usePartsData } from 'shared/hooks/usePartsData';

const useStyles = makeStyles({
  root: {
    // display: 'flex',
    minHeight: '46px',
    alignItems: 'center',
  },
});

const HighlightTextField = (props: FieldProps<any>) => {
  const classes = useStyles();
  const { record, source = '' } = props;

  const display = record[source];
  const [qs] = useLocationQuery();
  const search = qs.search || '';

  const partsData = usePartsData({ display, search });

  if (!partsData) return <span>{display}</span>;

  return (
    <span className={classes.root}>
      {partsData.map((part, index) => (
        <span
          key={index}
          style={{
            fontWeight: part.highlight ? 800 : 400,
            whiteSpace: 'nowrap',
          }}
        >
          {part.text}
        </span>
      ))}
      <br />
    </span>
  );
};

export default HighlightTextField;
