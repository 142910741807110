import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { add, format as formatDate, sub } from 'date-fns';
import React from 'react';
import { ToolbarProps, View } from 'react-big-calendar';
import useLocationQuery from 'shared/hooks/useLocationQuery';
import { AppraisalEvent } from 'views/Appraisal/types';

type ViewIcon = {
  view: View;
};
const viewIcons: ViewIcon[] = [
  {
    view: 'agenda',
  },
  {
    view: 'month',
  },
];

function CalendarToolbar(props: ToolbarProps<AppraisalEvent>) {
  const { view: selectedView, date, localizer, onNavigate, onView } = props;
  const [, onChangeQuery] = useLocationQuery();
  let display = formatDate(date, 'MMMM yyyy');

  const oneWeekBeforeDate = sub(date, { weeks: 1 });
  const oneWeekAfterDate = add(date, { weeks: 1 });

  if (selectedView === 'agenda') {
    display = `${formatDate(date, 'MMM dd yyyy')} - ${formatDate(oneWeekAfterDate, 'MMM dd yyyy')}`;
  } else if (selectedView === 'day') {
    display = formatDate(date, 'EEEE MMM dd');
  }
  return (
    <Grid alignItems="center" container justify="space-between" spacing={3}>
      <Grid item>
        <Box color="text.primary">
          <IconButton
            color="inherit"
            onClick={() => {
              if (selectedView === 'agenda') {
                onNavigate('DATE', oneWeekBeforeDate);
              } else {
                onNavigate('PREV');
              }
            }}
          >
            <ChevronLeft />
          </IconButton>
          <Button size="small" onClick={() => onNavigate('TODAY')}>
            {localizer.messages.today}
          </Button>
          <IconButton
            color="inherit"
            onClick={() => {
              if (selectedView === 'agenda') {
                onNavigate('DATE', oneWeekAfterDate);
              } else {
                onNavigate('NEXT');
              }
            }}
          >
            <ChevronRight />
          </IconButton>
        </Box>
      </Grid>
      <Hidden smDown>
        <Grid item>
          <Typography variant="h6" color="textPrimary">
            {display}
          </Typography>
        </Grid>
        <Grid item>
          <ButtonGroup>
            {viewIcons.map(({ view }) => (
              <Button
                style={
                  selectedView === view
                    ? {
                      backgroundImage: 'none',
                      boxShadow: 'inset 0 3px 5px rgba(0, 0, 0, 0.125)',
                      backgroundColor: '#e6e6e6',
                      borderColor: '#adadad',
                    }
                    : {}
                }
                key={view}
                onClick={(e) => {
                  e.stopPropagation();
                  onView(view);
                  onChangeQuery({ calendarView: view });
                }}
              >
                {props.localizer.messages[view] ?? ''}
              </Button>
            ))}
          </ButtonGroup>
        </Grid>
      </Hidden>
    </Grid>
  );
}

export default CalendarToolbar;
