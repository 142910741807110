import DateFnsUtils from '@date-io/date-fns';
import { Box, Card, Chip, Divider, IconButton, Typography, useMediaQuery } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import debounce from 'lodash/debounce';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useDataProvider, useRedirect } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';

import { AddToCalendar } from 'shared/components/AddToCalendar/AddToCalendar';
import { getTime, isToday, isTomorrow, isYesterday } from 'shared/components/Pickers';
import useFormPermissions from 'shared/hooks/useResourcePermissions';
import { abbreviateLastName } from 'shared/utils';
import getAppraisalPermission from 'views/Appraisal/permission';
import MeetingInput from '../MeetingInput';
import { activeDateFieldType } from './types';

import { styleRight } from 'shared/hooks/useEditFormStyle';

type Props = {
  setActiveDateField: Dispatch<SetStateAction<activeDateFieldType | undefined>>;
  workflowEdit: boolean;
  setOpenDateModal: Dispatch<SetStateAction<boolean>>;
  inspectionEdit: boolean;
  setInspectionEdit: Dispatch<SetStateAction<boolean>>;
  meetingContacts: any;
  setMeetingContacts: Dispatch<SetStateAction<any>>;
};

const InspectionSection = ({
  setActiveDateField,
  workflowEdit,
  setOpenDateModal,
  inspectionEdit,
  setInspectionEdit,
  meetingContacts,
  setMeetingContacts,
}: Props) => {
  const redirect = useRedirect();

  const classes = styleRight();

  const dataProvider = useDataProvider();

  const [{ permissions }] = useFormPermissions({ getPermission: getAppraisalPermission });
  const formData = useFormState();
  const form = useForm();

  const getInspectionFields = (initialValue: boolean) => ({
    inspection_date: initialValue,
    inspection_contact_ids: initialValue,
  });

  const [inspectionFields, setInspectionFields] = useState<any>(getInspectionFields(false));
  const [inspectionContacts, setInspectionContacts] = useState<any>([]);

  const setInspections = (val: boolean) => {
    setInspectionFields(getInspectionFields(val));
    setInspectionEdit(val);
  };

  const save = useCallback(debounce(form.submit, 0), []);

  useEffect(() => {
    let contacts: any = [];
    if (formData?.values?.inspection_contact_ids) {
      dataProvider.getMany('contacts', { ids: formData?.values?.inspection_contact_ids }).then(({ data }) => {
        contacts = data.map((item: any) => ({
          id: item?.id,
          full_name: item?.full_name,
          first_name: item?.first_name,
          last_name: item?.last_name,
          email: item?.email,
          phone: item?.phone_number,
          appraisalsCount: item?.appraisals_count,
          contact_type_id: item?.contact_type_id,
          type: item?.type,
        }));
        setMeetingContacts(contacts);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData?.values?.inspection_contact_ids]);

  const isStartRange = useMediaQuery('(min-width: 960px)');
  const isEndRange = useMediaQuery('(max-width: 1540px)');

  return (
    <Card
      variant="outlined"
      classes={{ root: `${classes.card} ${classes.overflow} ${classes.gap}` }}
      style={{ paddingBottom: '10px' }}
    >
      <Box className={classes.flexBox}>
        <Typography classes={{ root: `${classes.heading} ${classes.heading500} ${classes.fontLarge}` }}>
          Inspection
        </Typography>
        {/* {isFormDirty() && inspectionEdit && ( */}
        {inspectionEdit && (
          <IconButton
            className={classes.saveActionButton}
            edge="end"
            onClick={() => {
              form.change('inspection_contact_ids', inspectionContacts);
              save();
              setInspectionEdit(false);
              setInspections(false);
            }}
          >
            <SaveIcon classes={{ root: classes.icon }} />
          </IconButton>
        )}
        {permissions.edit && !inspectionEdit && (
          <IconButton
            className={classes.topEditBtn}
            edge="end"
            disabled={inspectionEdit}
            onClick={() => setInspections(true)}
          >
            <EditIcon classes={{ root: classes.icon }} />
          </IconButton>
        )}
        {inspectionEdit && (
          <IconButton
            classes={{ root: classes.topEditBtn }}
            disabled={!inspectionEdit}
            edge="end"
            onClick={() => {
              setInspectionEdit(false);
              setInspections(false);
              Object.keys(formData.dirtyFields).forEach((field: string) => {
                const [a, b] = field.split('.');
                if (a && b) {
                  form.change(field, formData.initialValues[a][b]);
                } else {
                  form.change(field, formData.initialValues[a]);
                }
              });
            }}
          >
            <CloseIcon classes={{ root: classes.icon }} />
          </IconButton>
        )}
      </Box>
      <Divider classes={{ root: classes.dividerSubSelect }}></Divider>
      <Box className={classes.flexBox}>
        <Typography classes={{ root: `${classes.heading} ${classes.heading400} ${classes.labelWidth}` }}>
          Date & Time
        </Typography>
        <Box pr={2} className={classes.inputContainer}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              onClick={(e: any) => {
                if (inspectionEdit) {
                  setOpenDateModal(true);
                  setActiveDateField({ label: 'Inspection Date and Time', key: 'inspection_date' });
                }
              }}
              FormHelperTextProps={{ style: { display: 'none' } }}
              variant="dialog"
              inputVariant="outlined"
              disabled={!permissions.edit || !inspectionFields.inspection_date || !inspectionEdit}
              fullWidth
              keyboardIcon={''}
              onChange={(date: any) => console.log('')}
              value={formData?.values?.inspection_date}
              labelFunc={(date: any) => {
                if (formData?.values?.inspection_date) {
                  if (new Date(date).toString() === 'Invalid Date') return '';
                  if (isToday(date)) {
                    return `Today @ ${getTime(date)}`;
                  } else if (isTomorrow(date)) {
                    return `Tomorrow @ ${getTime(date)}`;
                  } else if (isYesterday(date)) {
                    return `Yesterday @ ${getTime(date)}`;
                  } else {
                    return `${new Date(date).toLocaleDateString()} @ ${getTime(date)}`;
                  }
                } else return '';
              }}
              format="MM/dd/yyyy @ hh:mma"
              margin="dense"
              InputProps={{
                placeholder: `Click ${workflowEdit ? '' : 'pencil'} to edit`,
                classes: {
                  root: `${classes.cssOutlinedInput} ${classes.inputFontWeight} ${inspectionFields.inspection_date ? classes.cssOutlinedActive : classes.cssOutlinedDisabled
                    }`,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                },
              }}
            />
          </MuiPickersUtilsProvider>

          {!inspectionEdit && formData.values.inspection_date && (
            <Box
              className={`${classes.addToCalendarBtn}`}
              style={{ display: isStartRange && isEndRange ? 'none' : '' }}
            >
              <AddToCalendar
                date={formData.values.inspection_date}
                title={`Appraisal Inspection: ${formData.values.appraisal_file_number}`}
                location={formData.values.property.location_address}
              />
            </Box>
          )}

          {/* not in use currently since we are using save button from date time picker modal */}
          {/* <IconButton
                size="small"
                className={`${classes.fieldEditBtn} ${classes.fieldEditDateBtn}`}
                edge="end"
                disabled={inspectionFields.inspection_date && !formData.dirtyFields.inspection_date}
              >
                {inspectionFields.inspection_date && formData.dirtyFields.inspection_date && (
                  <SaveIcon fontSize="small" classes={{ root: classes.icon }} onClick={() => save()} />
                )}
                {inspectionFields.inspection_date && formData.dirtyFields.inspection_date && (
                  <CloseIcon
                    fontSize="small"
                    classes={{ root: classes.icon }}
                    onClick={() => form.change('inspection_date', formData.initialValues.inspection_date)}
                  />
                )}
              </IconButton> */}
        </Box>
      </Box>
      <Box className={classes.flexBoxAuto}>
        <Typography classes={{ root: `${classes.heading} ${classes.heading400} ${classes.labelWidth}` }}>
          Meeting with
        </Typography>
        {formData?.values?.inspection_contact_ids?.length === 0 && !inspectionEdit ? (
          <Box pr={2} className={classes.inputContainer}>
            <Typography classes={{ root: `${classes.nextTask} ${classes.disabledInput}` }}>
              Click pencil to edit
            </Typography>
          </Box>
        ) : (
          <Box
            pr={2}
            pb={1}
            pt={1}
            mb={formData?.values?.inspection_contact_ids?.length !== 0 || inspectionEdit ? 0 : 4}
            className={classes.inputContainer}
          >
            {inspectionEdit ? (
              <MeetingInput
                allContacts={formData?.values?.all_contact_ids}
                meetingContacts={formData?.values?.inspection_contact_ids}
                clientId={formData?.values?.client_id}
                setMeetingContacts={(data: any) => {
                  const contacts = data.map((item: any) => item.id);
                  setInspectionContacts(contacts);
                }}
              />
            ) : meetingContacts?.length ? (
              meetingContacts?.map((choice: any) => (
                <Chip
                  key={choice?.id}
                  label={`${abbreviateLastName(
                    choice?.first_name + (choice?.last_name ? ' ' + choice?.last_name : ''),
                  )} (${choice?.type})`}
                  style={{
                    backgroundColor: '#e0e0e0',
                    color: 'rgba(0, 0, 0, 0.87)',
                    height: '32px',
                    margin: '3px',
                  }}
                  onClick={() => redirect(`/appraisals/${formData?.values?.id}#contacts`)}
                />
              ))
            ) : (
              ''
            )}
          </Box>
        )}
      </Box>
    </Card>
  );
};

export default InspectionSection;
